import "@rainbow-me/rainbowkit/styles.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import './assets/styles/_root.scss';
import {HashRouter, Route, Routes} from "react-router-dom";

import {RainbowKitProvider} from "@rainbow-me/rainbowkit";
import {WagmiConfig} from 'wagmi'

import {client, chains} from "./config/BcConfigure";
import {CustomTheme, Disclaimer, CustomAvatar} from "./components/ConnectCustomize";
import MainPanel from "./pages/MainPanel";
import Nft from "./pages/nft";
import Grained from "./components/Grained";
import BcProvider from "./context/BcContext";
import {NotifyProvider} from "./context/NotifyContext";
import Notify from "./components/Notifiy";
import StateLoader from "./components/StateLoader";
import IboContainer from './components/ibo/IboContainer';

function App() {


    // noinspection JSValidateTypes
    return (
        <>
            <NotifyProvider>
                <Notify/>
                <HashRouter>
                    <Grained/>
                    <WagmiConfig client={client}>
                        <RainbowKitProvider modalSize="compact" chains={chains} theme={CustomTheme}
                                            avatar={CustomAvatar}
                                            appInfo={{appName: 'Convergence finance', disclaimer: Disclaimer}}>
                            <BcProvider>
                                <StateLoader/>
                                <Routes>
                                    {/* <Route index={undefined} path="/" element={<MainPanel/>}/> */}
                                    <Route index={undefined} path="/" element={<IboContainer/>}/>
                                    <Route path="/nft" element={<Nft/>}/>
                                </Routes>
                            </BcProvider>
                        </RainbowKitProvider>
                    </WagmiConfig>
                </HashRouter>
            </NotifyProvider>
        </>
    );
}

export default App;
