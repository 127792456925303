import BondListRowDisplay from './BondListRowDisplay';

import BondListRowAction from './BondListRowAction';
import Loader from '../Loader';


function BondListRow({bondInfos}) {

    if (bondInfos?.loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    return (
        <div className="border-gray-quiet py-3 border-top-1">
            <BondListRowDisplay bondInfos={bondInfos}/>
            <div className="w-full text-center" hidden={!bondInfos.activeData.isExpanded}>
                <BondListRowAction bondInfos={bondInfos}/>
            </div>
        </div>
    );
}

export default BondListRow;


