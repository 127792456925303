import { useContext } from 'react';

import { InputNumber } from 'primereact/inputnumber';
import { IboContext } from '../../context/IboContext';
import { UPDATE_VALUE_CVG, UPDATE_VALUE_UNDERLAYING, UPDATE_MAX_CVG } from '../../context/ibo.reducer';

function BondListActionDuoInputs({bondInfos}) {
    const { dispatchIboInfos } = useContext(IboContext);

    const tokenName = bondInfos?.staticData?.token?.token;

    return (
        <div className='flex align-items-center justify-content-center md:justify-content-start'>
            <div>
                {/* ASSET DEPOSITED INPUT */}
                <div className="flex align-items-center mb-4">
                    <div className="w-5 mr-2 hidden md:block">Deposit</div>
                    <img src={`/images/token/${tokenName.toUpperCase()}.svg`} alt="logo" className="staking-asset-logo w-2rem mr-2" title={tokenName} />
                    <div className="flex">
                        <InputNumber
                            inputId="max-buttons"
                            className="appearance-none "
                            value={bondInfos.activeData.inputValueUnderlaying}
                            onChange={(e) => {
                                dispatchIboInfos({
                                    type: UPDATE_VALUE_UNDERLAYING,
                                    data: { bondName: tokenName, value: e.value },
                                });
                            }}
                            placeholder={'Enter ' + tokenName + ' amount'}
                            mode="decimal"
                            locale="en-US"
                            minFractionDigits={2}
                            maxFractionDigits={20}
                        />
                    </div>
                </div>

                {/* CVG RECEIVED INPUT */}
                <div className="flex mt-3 align-items-center ">
                    <div className="w-5 mr-2 hidden md:block">Receive</div>
                    <img src={`/images/token/CVG.svg`} alt="logo" className="staking-asset-logo w-2rem mr-2" title={"CVG"} />
                    <div className="flex">
                        <InputNumber
                            inputId="max-buttons"
                            className="appearance-none "
                            value={bondInfos.activeData.inputValueCvg}
                            onChange={(e) => {
                                dispatchIboInfos({
                                    type: UPDATE_VALUE_CVG,
                                    data: { bondName: tokenName, value: e.value },
                                });
                            }}
                            placeholder="Enter Cvg Amount"
                            mode="decimal"
                            locale="en-US"
                            minFractionDigits={2}
                            maxFractionDigits={20}
                        />
                    </div>
                </div>
            </div>
            <div className='ml-2'>
                <button
                    type="button"
                    onClick={() => {
                        dispatchIboInfos({
                            type: UPDATE_MAX_CVG,
                            data:{bondName:tokenName}
                        });
                    }}
                    className="Btn Btn-small-action">
                    Max
                </button>
            </div>
        </div>
    );
}

export default BondListActionDuoInputs;
