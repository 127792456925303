
import React from "react"
import twitterLogo from "../assets/socials/twitter.svg";
 import discordLogo from "../assets/socials/discord.svg";
 import mediumLogo from "../assets/socials/medium.svg";

const links= {
    twitter : process.env.REACT_APP_LINK_TWITTER,
    discord : process.env.REACT_APP_LINK_DISCORD,
    medium : process.env.REACT_APP_LINK_MEDIUM,
    twitterLogo,discordLogo,mediumLogo
};

function Footer() {

    function link(type){
        const url =links[type];
        if(!url)
            return ( <></>)

        return (
        <a href={links[type]||'#1'} target="_blank" rel="noreferrer" className="Footer-link">
            <i><img src={links[type+'Logo']} alt={type}/> </i>  <span className="hidden md:inline">{type}</span>
        </a>
        )
    }


  return (
    <>
      <div className="Footer text-center" role="menu">
          {link('twitter')}
          {link('discord')}
          {link('medium')}
      </div>
    </>
  )

}

export default Footer;