import {useContext, useEffect, useRef} from "react";
import {Toast} from "primereact/toast";
import NotifyContext from "../context/NotifyContext";
import Loader from "./Loader";


function Notify() {

    const {notify} = useContext(NotifyContext);
    const toast = useRef(null);
    const toastPending = useRef(null);
    
    useEffect(() => {

        if(!notify || !notify.type)
            return;

        if (notify?.type === 'error') {
            console.error(notify.message)
        }

        let inside = notify?.message;
        if (notify.tx && notify?.type === 'warn') {

            inside = (
                <>
                    <div>
                        <Loader/>
                        <span style={{verticalAlign: "middle"}}>  A transaction is running</span>
                    </div>
                </>
            )
        }

        let sticky =  false;
        let currentToast = toast?.current;
        if (notify.tx && notify?.type === 'warn') {
            currentToast = toastPending?.current;
            sticky= true;
        } else {
            toastPending?.current.clear();
        }

        currentToast?.show(
            {
                severity: notify?.type,
                summary: notify?.type + " Message",
                detail: inside,
                life: 7000,
                sticky:sticky
            });
    }, [notify]);

    return (
        <>
            <Toast ref={toast} className="Notify" position="top-center"/>
            <Toast ref={toastPending} className="Notify" position="top-center"/>
        </>
    )
}

export default Notify;