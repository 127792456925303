import {ethers} from "ethers";
import {bigNumberFormat} from "../actions/utils";

function ApproveBtn({currentAllowance, handleApprove, className}) {

    if (currentAllowance.eq(ethers.constants.MaxUint256)) {
        return (<></>)
    }

    let display = 'Approve';
    let title = 'Approve';

    if (currentAllowance.gt(ethers.constants.Zero)) {
        display = '+Approve';
        title = `current allowance : ${bigNumberFormat(currentAllowance)}`;
    }
    return (
        <button type="button" onClick={handleApprove} className={`Btn ${className || ''}`}
                title={title}>{display}</button>
    )

}

export default ApproveBtn;