import { multiCallBc, multicallStandardHydrate } from './utils';
import ibo from "./ibo.json";
import erc20 from "./erc20.json";
import { TOKENS } from '../config/tokenResources';
import { BigNumber, ethers } from 'ethers';

const urlApi = process.env.REACT_APP_API_URL;

class IboService {

    async loadIboInit(wallet, provider) {



        const iboAddress = process.env.REACT_APP_CONTRACT_IBO;
        let calls = []
        calls.push({
            path: ['iboStartTimestamp'],
            method: 'iboStartTimestamp',
            address: iboAddress,
            params: [],
            abiIndex : 0
        });

        calls.push({
            path: ['iboDuration'],
            method: 'IBO_DURATION',
            address: iboAddress,
            params: [],
            abiIndex : 0
        });

        calls.push({
            path: ['bondParams', "FRAX"],
            method: 'getBondView',
            address: iboAddress,
            params: [1],
            abiIndex : 0
        });
        calls.push({
            path: ['bondParams', "CRV"],
            method: 'getBondView',
            address: iboAddress,
            params: [2],
            abiIndex : 0
        });        
        
        calls.push({
            path: ['bondParams', 'CVX'],
            method: 'getBondView',
            address: iboAddress,
            params: [3],
            abiIndex : 0
        });


        calls.push({
            path: ['soldDuringPrivilege'],
            method: 'soldDuringPrivilege',
            address: iboAddress,
            params: [wallet],
            abiIndex : 0
        });


        // Call erc20
        Object.entries(TOKENS).forEach(([k,v ]) => {
            calls.push({
                path: ['balanceAllowance', k, "balanceOf"],
                method: 'balanceOf',
                address: v.address,
                params: [wallet],
                abiIndex : 1
            });
            calls.push({
                path: ['balanceAllowance', k, "allowance"],
                method: 'allowance',
                address: v.address,
                params: [wallet,iboAddress],
                abiIndex : 1
            });
        })

        // Call erc721
        calls.push({
            path: ['nftParams', "balanceOf"],
            method: 'balanceOf',
            address: iboAddress,
            params: [wallet],
            abiIndex : 0
        });

        const result = {};
        
        const hydrateFunc = multicallStandardHydrate(result)
        await multiCallBc(calls, [ibo, erc20], provider , hydrateFunc);
        calls = [];
        calls = [];
        for (let index = 0; index < result.nftParams.balanceOf.toNumber(); index++) {
            calls.push({
                path: ['nftParams', "tokenIds", index],
                method: 'tokenOfOwnerByIndex',
                address: iboAddress,
                params: [wallet, index],
                abiIndex : 0
            });
            
        }
        let totalToSell = BigNumber.from(0);
        let totalSold = BigNumber.from(0);
        Object.entries(result.bondParams).forEach(([_, bond]) => {
            totalToSell =  bond.maxCvgToMint.add(totalToSell);
            totalSold = bond.totalCvgMinted.add(totalSold);
        });

        await multiCallBc(calls, [ibo], provider , hydrateFunc);
        calls = [];

        for (let index = 0; index < result.nftParams.balanceOf.toNumber(); index++) {
            
            calls.push({
                path: ['nftParams', "tokenData", result.nftParams.tokenIds[index]],
                method: 'totalCvgPerToken',
                address: iboAddress,
                params: [result.nftParams.tokenIds[index]],
                abiIndex : 0
            });
            
        }
        await multiCallBc(calls, [ibo], provider , hydrateFunc);
        const actualTimestamp = (await provider.getBlock()).timestamp;
        const startTimestamp = result.iboStartTimestamp.toNumber();
        const iboDuration = result.iboDuration.toNumber();

        const totalSoldNumber  = ethers.utils.formatEther(totalSold);
        const totalToSellNumber  = ethers.utils.formatEther(totalToSell);


        const formattedResult = {
            balanceAllowance : result.balanceAllowance,
            iboGlobalData : {
                iboStartTimestamp : startTimestamp,
                iboEndTimestamp : startTimestamp + iboDuration ,
                percentageDuration : ((actualTimestamp - startTimestamp) / iboDuration ) * 100,
                totalSold : totalSoldNumber,
                totalToSell : totalToSellNumber,
                percentageBought : (totalSoldNumber / totalToSellNumber ) * 100,
                timeLeft : startTimestamp + iboDuration - actualTimestamp,
                actualTimestamp,
                soldDuringPrivilege : result.soldDuringPrivilege
            },
            isPrivilege : (await provider.getBlock()).timestamp - result.iboStartTimestamp.toNumber() < 60 * 45,
            bondParams : {},
            nftParams : result.nftParams
        };

        Object.entries(result.bondParams).forEach(([k,v]) => {
            Object.entries(v).forEach(([k1, v1])=> {
                if(Number(isNaN(k1))){
                    if(!formattedResult.bondParams[k]){
                        formattedResult.bondParams[k] = {};
                    }
                    formattedResult.bondParams[k][k1] = v1;
                }
            })
        });

        return formattedResult;
    }


    async getOne(wallet, bondName, provider) {
        
        const iboAddress = process.env.REACT_APP_CONTRACT_IBO;

        let bondId;
        if(bondName === "FRAX"){
            bondId = 1;
        }
        else if(bondName === "CRV"){
            bondId = 2;
        }
        else if(bondName === 'CVX'){
            bondId = 3;
        }
        let calls = []

        calls.push({
            path: ['bondParams'],
            method: 'getBondView',
            address: iboAddress,
            params: [bondId],
            abiIndex : 0
        });

        // Call erc20
        calls.push({
            path: ['balanceAllowance', "balanceOf"],
            method: 'balanceOf',
            address: TOKENS[bondName].address,
            params: [wallet],
            abiIndex : 1
        });
        calls.push({
            path: ['balanceAllowance', "allowance"],
            method: 'allowance',
            address: TOKENS[bondName].address,
            params: [wallet,iboAddress],
            abiIndex : 1
        });

        // Call erc721
        calls.push({
            path: ['nftParams', "balanceOf"],
            method: 'balanceOf',
            address: iboAddress,
            params: [wallet],
            abiIndex : 0
        });

        const result = {};
        
        const hydrateFunc = multicallStandardHydrate(result)
        await multiCallBc(calls, [ibo, erc20], provider , hydrateFunc);
        calls = [];
        for (let index = 0; index < result.nftParams.balanceOf.toNumber(); index++) {
            calls.push({
                path: ['nftParams', "tokenIds", index],
                method: 'tokenOfOwnerByIndex',
                address: iboAddress,
                params: [wallet, index],
                abiIndex : 0
            });
            
        }


        await multiCallBc(calls, [ibo], provider , hydrateFunc);
        calls = [];

        for (let index = 0; index < result.nftParams.balanceOf.toNumber(); index++) {
            
            calls.push({
                path: ['nftParams', "tokenData", result.nftParams.tokenIds[index]],
                method: 'totalCvgPerToken',
                address: iboAddress,
                params: [result.nftParams.tokenIds[index]],
                abiIndex : 0
            });
            
        }
        await multiCallBc(calls, [ibo], provider , hydrateFunc);

        const formattedResult = {
            balanceAllowance : result.balanceAllowance,
            bondParams : {},
            nftParams : result.nftParams
        };


        Object.entries(result.bondParams).forEach(([k1, v1])=> {
            if(Number(isNaN(k1))){
                formattedResult.bondParams[k1] = v1;
            }
        })
        return formattedResult;
    }


    async getMerkle(address){
        const uri = `${urlApi}/proof/ibo/${address}`;
        const response = await fetch(uri, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        
        return (await response.json()) || {listingType: 0, proof: null};
    }


}

const service = new IboService();
export default service;
