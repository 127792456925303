import { useContext, useEffect, useState } from 'react';
import Loader from '../Loader';
import Panel from '../Panel';
import { IboContext } from '../../context/IboContext';
import BondListRow from './BondListRow';
import { secondsToMinutsSeconds } from '../../actions/utils';

function BondList() {
    const {iboInfos} = useContext(IboContext);
    const [counter, setCounter] = useState(undefined);

    useEffect(() => {
        if(iboInfos && iboInfos?.iboGlobalData?.timeLeft && counter === undefined){
            setCounter(iboInfos?.iboGlobalData.timeLeft);
        }
        if(counter) {
            const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
          return () => clearInterval(timer);
        }
    }, [iboInfos?.iboGlobalData?.timeLeft, counter]);

    if (iboInfos?.bondsData?.loading) {
        return (
            <div>
                <Panel>
                    <Loader />
                </Panel>
            </div>
        );
    }

    if (iboInfos?.merkleData?.message) {
        return (
            <div>
                <Panel>
                    <div className='w-12 text-center'>
                        <span>You don't have a Cvg Pepe or are not a Centaurus</span>
                    </div>
                    <div className='w-12 text-center mt-4 '>
                        <span className='roi  p-2'>{secondsToMinutsSeconds(Number(counter) - 315 * 60)}</span>
                    </div>
                    <div className='flex justify-content-center'>
                        <img className='w-11 md:w-6' src='./images/cvg-locked.gif' alt='locked-ibo'></img>
                    </div>
                </Panel>
            </div>
        );
    }

    return (
        <Panel className="mt-1">
            <div className=" justify-content-between pb-4 text-xl p-column-title color-tonic hidden md:flex">
                <div className="md:w-8rem m-0 mr-2 text-center">Asset</div>
                <div className="md:w-15rem text-center">CVG Available</div>
                <div className="w-4rem text-center">Discount</div>
                <div className="w-5rem text-center">Price</div>
                <div className="w-2rem text-center"></div>
            </div>
            {Object.entries(iboInfos?.bondsData).map(([k, item], i) => {
                return (
                    <BondListRow key={i} bondInfos={item} />
                );
            })}
        </Panel>
    );
}

export default BondList;
