import { useContext } from 'react';
import { IboContext } from '../../context/IboContext';
import { formatEther } from 'ethers/lib/utils';
import { Dropdown } from 'primereact/dropdown';
import BondListActionDuoInputs from './BondListActionDuoInputs';
import BondListActionButton from './BondListActionButtons';
import { UPDATE_BOND_DROPDOWN, UPDATE_SLIPPAGE } from '../../context/ibo.reducer';
import { SelectButton } from 'primereact/selectbutton';
import BondListActionChart from './BondListActionChart';


function BondListRowAction({bondInfos}) {
    const { iboInfos, dispatchIboInfos } = useContext(IboContext);
    const slippage = [
        { label: '0.1%', code: 0.001 },
        { label: '0.5%', code: 0.005 },
        { label: '1%', code: 0.01 },
        { label: '2%', code: 0.02 },
        { label: '5%', code: 0.05 },
        { label: '10%', code: 0.1 },
        
    ];
    const tokenName = bondInfos.staticData.token.token;
    return (
        <>
        <div className="lg:flex lg:align-items-center py-3 xl:px-7 lg:px-4 lg:px-2 sm:px-0">
            <div className="lg:w-6 w-12 flex flex-column align-content-center">
                <div className="md:flex md:flex-row flex-column align-items-center my-4 w-12 md:justify-content-center">
                    {/* ACTIONS DEPOSIT */}
                    <div className="w-12 ">
                        <div className="mb-2 md:text-left text-sm text-center">
                            Available: {Number(formatEther(iboInfos?.balanceAllowance[tokenName].balanceOf)).toLocaleString('en-US', { minimumFractionDigits: 2 })}  {tokenName}
                        </div>
                        <div className='w-12 mb-4  flex align-items-center md:justify-content-start justify-content-center'>
                            <span className=' md:mr-2 text-sm'>Slippage: {" "}</span>
                            <SelectButton
                                
                                options={slippage}
                                value={bondInfos.activeData.activeSlippage}
                                itemTemplate={(option) => <span className="text-xs">{option.label}</span>}
                                onChange={(e) => {
                                    dispatchIboInfos({
                                        type: UPDATE_SLIPPAGE,
                                        data: { value: e.value, bondName: tokenName},
                                    });
                                }}
                                unselectable={false}
                            />
                        </div>

                        <BondListActionDuoInputs bondInfos={bondInfos}></BondListActionDuoInputs>
                    </div>

                    {/* DROPDOWN POSITION*/}
                    <div className="w-12  flex flex-column align-items-center md:justify-content-center">
                        <div className="mb-3 mt-3 md:mt-0">Position:</div>
                        <Dropdown
                            dropdownIcon="Dropdown-icon "
                            value={bondInfos.activeData.activeDropdownConfig}
                            options={bondInfos.activeData.nftDropdownConfig}
                            onChange={(e) => {
                                dispatchIboInfos({
                                    type: UPDATE_BOND_DROPDOWN,
                                    data: { value: e.value, bondName: tokenName},
                                });
                            }}
                            className='Dropdown-item max-w-12rem'
                            optionLabel="name"
                        ></Dropdown>

                        
                        {
                            (
                                bondInfos?.activeData?.activeTotalCvg ? 
                                <div className=" py-1 px-2 color-tonic">{Number(formatEther(bondInfos?.activeData?.activeTotalCvg))?.toLocaleString('en-US', {
                                maximumFractionDigits: 0,
                                })} {" "}
                                     CVG
                                </div> : ""
                            )
                        }

                    </div>
                </div>
                <div className="flex mt-4 w-12">
                    <BondListActionButton bondInfos={bondInfos}></BondListActionButton>
                </div>
            </div>
            <div className="lg:w-6 w-12">
                <BondListActionChart bondInfos={bondInfos}></BondListActionChart>
            </div>
        </div>
    </>
    );
}

export default BondListRowAction;
