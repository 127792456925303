import Panel from "./Panel";

import {useContext, useEffect, useState} from "react";
import { getNftInfos} from "../actions/SmartContractActions";
import {useAccount, useProvider, useSigner} from "wagmi";
import NotifyContext from "../context/NotifyContext";
import Loader from "./Loader";
import NftItem from "./NftItem";
import { formatEther, parseEther } from 'ethers/lib/utils';


function NftPanel() {

    const [nftInfos, setNftInfos] = useState({loaded: false});
    const {data: signer} = useSigner();
    const provider = useProvider();
    const {notifyError} = useContext(NotifyContext);
    const {address, isConnected} = useAccount()

    useEffect(() => {

        if (address && signer && !nftInfos.loaded && !nftInfos.loading) {
            setNftInfos({loading: true});
            getNftInfos(signer, provider).then((ob) => {
                
                if (typeof (ob) === 'string') {
                    notifyError(ob);
                    setNftInfos({error: true,loading: false,loaded:true});
                    return;
                }
                setNftInfos({loading: false,loaded:true, list: ob});
            });
        }
    }, [signer, nftInfos?.loading,  address]);


    if(!isConnected){
        return (
            <div className="grid  justify-content-center">
                <Panel className="col-12 md:col-4 mb-8">
                   You're not connected <a  href="/" className="color-tonic"> Go to main page</a>
                </Panel>
            </div>

        )
    }

    if (nftInfos?.loading || !nftInfos?.loaded) {
        return (
            <div className="grid  justify-content-center">
                <Panel className="col-12 md:col-4 mb-8">
                    <Loader/> Loading...
                </Panel>
            </div>

        )
    }


    if (!nftInfos?.list?.length) {
        return (
            <div className="grid  justify-content-center">
                <Panel className="col-12 md:col-4 mb-8">
                    You have no NFT
                </Panel>
            </div>
        )
    }

    let i = 1;
    return (

        <div>
            {
                nftInfos.list.filter(item => item.tokens.length > 0).map((item) => {
                    return (
                        <Panel className='mb-8'>
                            <div className='text-3xl color-tonic mb-4 ml-2'>{item.tokens.length} {item.type} token{item.tokens.length>1 ?"s":""}  : {Number(formatEther(item.totalCvg)).toLocaleString('en-US',{
                                maximumFractionDigits:0
                            }) } CVG</div>
                            <div className='grid'>
                                {item.tokens.map((token) => {
                                    return (
                                        <div key={i++}  className="col-12 md:col-4 mb-8">
                                            <NftItem item={token} nftTpe={item.type}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </Panel>
                    )
                })
            }
        </div>


    )

}


export default NftPanel;