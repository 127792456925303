import { numberFormatter } from "../actions/utils";

const { ethers } = require("ethers");

function ProgressBarWithLimit({ min, max, current, displayCurrent = true }) {
  current = parseInt(ethers.utils.formatEther(current.toString()));
  // current = 6185

  max = parseInt(ethers.utils.formatEther(max.toString()));
  min = parseInt(ethers.utils.formatEther(min.toString()));
  const totalLength = max - min;
  let percent = ((current - min) / totalLength) * 100;
  if (current === 0) percent = 0;
  if (current === max) percent = 100;

  const currentDisplay = () => {
    if (!displayCurrent) return <></>;

    return (
      <>
        <span
          className="Progress-current-indicator"
          style={{ left: `${percent}%` }}
        >
          <span className="">
            {current === 0 ? "-" : `$${numberFormatter(current)}`}{" "}
          </span>
        </span>
        <div className="Progress-inner" style={{ width: `${percent}%` }}></div>
      </>
    );
  };

  return (
    <>
      <div className="Progress">
        <span className="Progress-min-indicator" style={{ left: `${0}%` }}>
          <span>${numberFormatter(min)} </span>
        </span>
        <span className="Progress-max-indicator" style={{ left: `${100}%` }}>
          <span>${numberFormatter(max)} </span>
        </span>
        {currentDisplay()}
      </div>
    </>
  );
}

export default ProgressBarWithLimit;
