// noinspection HtmlUnknownTarget

import React, {useState} from 'react';
import ConvLogo from "../assets/logo.svg";
import {Link, useLocation} from "react-router-dom";
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";


function Menu() {


    const chainKey =  process.env.REACT_APP_CHAIN_KEY;
    const testNetChains = ['cvg','goerli','localhost','hardhat'];
    const isTestnet = testNetChains.indexOf(chainKey.toLowerCase())!==-1
    const location = useLocation();
    const [visible, setVisible] = useState(false);

    const LogoItem = () => {
        return (<img alt="convergence" src={ConvLogo}/>)
    }

    function getLink(to, label) {
        const activeClassName = location.pathname === to ? "is-active" : "";
        return (<li key={to} className={activeClassName}><Link to={to}>{label}</Link></li>)
    }

    const menu = (
        <nav className="Menu">
            <ul className="Nav flex flex-column md:flex-row">
                {getLink("/", "ibo")}
                <li className="TopMenu-logo hidden md:inline-block"><LogoItem/></li>
                {getLink("/nft", "NFT")}
            </ul>
        </nav>
    )

    return (
        <>
            <div className={`TopMenu  hidden md:block ${isTestnet?'Testnet':''}`} >
                {menu}
            </div>
            <div className={`md:hidden TopMenu-mobile ${isTestnet?'Testnet':''}`} >
                <Button icon="pi pi-bars" onClick={() => setVisible(true)} className=" Btn Btn-nostyle m-2">
                    <span className="p-sr-only"> Open the menu</span>
                </Button>
                <span className="TopMenu-mobile-title">Convergence</span>
                <Sidebar visible={visible} position="top" onHide={() => setVisible(false)} className=" Menu--mobile">
                    <div className="TopMenu-logo--mobile"><LogoItem/></div>
                    {menu}
                </Sidebar>
            </div>
        </>
    )


}

export default Menu;