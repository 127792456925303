import React, {useContext, useEffect, useReducer, useState} from 'react';
import {getNftInfos} from "../actions/SmartContractActions";
import {useProvider, useSigner} from "wagmi";
import NotifyContext from "./NotifyContext";

const coins = [
    {name: 'DAI', code: 'dai'}
    , {name: 'FRAX', code: 'frax'}
];

let selectedNftId = 0


const BcContext = React.createContext(null);
const BcProvider = ({children}) => {

    const [currentCoin, setCurrentCoin] = useState(coins[0]);
    const [amount, setAmount] = useState(0);
    const [nftInfos, setNftInfos] = useState(undefined);
    const [activeTab, setActiveTab] = useState(0);
    const {data: signer} = useSigner();
    const provider = useProvider();
    const {notifyError} = useContext(NotifyContext);
    const [selectedNft, setSelectedNft] = useState(undefined);



    useEffect(() => {
        if (nftInfos === undefined && signer) {
            setNftInfos({loading: true});
            getNftInfos(signer, provider).then((ob) => {
                if (typeof (ob) === 'string') {
                    notifyError(ob);
                    setNftInfos({error: true, loading: false, loaded: true});
                    return;
                }
                ob = ob.sort((a, b) => {
                    return Number(a.tokenId) - Number(b.tokenId);
                });

                setNftInfos({loading: false, loaded: true, list: ob});
                if (ob?.length > 0) {

                    const selected =  ob.find((nft) => {
                        return nft.code === selectedNftId
                    })||ob[0];
                    setSelectedNft(selected);
                }

                // setSelectedNft(ob[0]);
            });
        }

    });

    function resetNftInfos(code) {
        selectedNftId = code;
        setNftInfos(undefined);
    }

    function reducer(state, action) {
        switch (action.type) {
            case 'update':
                return {...action.ob};
            default:
                throw new Error();
        }
    }

    const [userInfo, dispatchUserInfo] = useReducer(reducer, null);
    const [bcState, dispatchBcState] = useReducer(reducer, {loading: true});


    function updateBcState(newState) {
        dispatchBcState({type: 'update', ob: {...newState}});
    }

    function updateUserInfo(newInfo) {
        dispatchUserInfo({type: 'update', ob: {...newInfo}});
    }

    function resetBcState() {
        dispatchBcState({...{type: 'update'}, ...{loading: true}});
    }

    const providerValue = {
        updateBcState,
        bcState,
        resetBcState,
        currentCoin,
        setCurrentCoin,
        coins,
        userInfo,
        updateUserInfo,
        amount,
        setAmount,
        nftInfos,
        resetNftInfos,
        activeTab, setActiveTab, selectedNft, setSelectedNft
    };

    return (
        <BcContext.Provider value={providerValue}>{children}</BcContext.Provider>
    );
};

export default BcProvider;
export {BcContext};