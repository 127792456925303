import {callBc, loadConfigFile, multiCallBc, multicallStandardHydrate} from "./utils"


const {ethers, BigNumber} = require("ethers");


const urlApi = process.env.REACT_APP_API_URL;

export const PRESALE_ALLOCATION_TYPE = {
    NONE: 0,
    S: 2,
    M: 3,
    L: 4,
}
export const SALE_STATE = {
    NOT_STARTED: 0,
    PRE_SEED: 1,
    SEED: 2,
    WL: 3,
    ENDED: 4
};
const allowance = ethers.utils.parseEther('1000000');
const seedContractInfo = {abi: null, contract: null, address: process.env.REACT_APP_CONTRACT_SEED};
const presaleContractInfo = {abi: null, contract: null, address: process.env.REACT_APP_CONTRACT_PRESALE};
const iboContractInfo = {abi: null, contract: null, address: process.env.REACT_APP_CONTRACT_IBO};
let erc20AbiJson, daiContract, fraxContract;

const _initErc20Contract = async (signer, provider) => {
    if (!erc20AbiJson)
        erc20AbiJson = await loadConfigFile('erc20.abi');

    const [seedContract,] = await _initContract(signer, provider)
    if (!seedContract)
        return;

    if (!daiContract) {
        const _dai = await seedContract.Dai();
        daiContract = await new ethers.Contract(_dai, erc20AbiJson.abi, signer);
    }

    if (!fraxContract) {
        const _frax = await seedContract.Frax();
        fraxContract = await new ethers.Contract(_frax, erc20AbiJson.abi, signer);
    }
}

async function _initContractType(contractInfo, signed, signer, provider) {

    let contract = null;

    if (!contractInfo.address)
        return;

    if (signed) {
        if (!contractInfo.contract)
            contractInfo.contract = await new ethers.Contract(contractInfo.address, contractInfo.abi.abi, signer);
        contract = contractInfo.contract
    } else {
        contract = await new ethers.Contract(contractInfo.address, contractInfo.abi.abi, provider);
    }

    let code = await provider.getCode(contractInfo.address);
    if (code === '0x')
        contract = null;
    return contract


}

const _initContract = async (signer, provider) => {
    if (!seedContractInfo.abi)
        seedContractInfo.abi = await loadConfigFile('abi.seed');

    if (!presaleContractInfo.abi)
        presaleContractInfo.abi = await loadConfigFile('abi.presale');

    if (!iboContractInfo.abi)
        iboContractInfo.abi = await loadConfigFile('ibo');

    let signed = !!signer;
    const seed = await _initContractType(seedContractInfo, signed, signer, provider);
    const presale = await _initContractType(presaleContractInfo, signed, signer, provider);
    const ibo = await _initContractType(iboContractInfo, signed, signer, provider);

    return [seed, presale, ibo];
}

const _getTokenUserInfo = async (provider, signer, contractInfo) => {

    await _initErc20Contract(signer, provider);
    const address = await signer.getAddress();

    const calls = []
    calls.push({
        path: ['allowances', 'dai'],
        method: 'allowance',
        address: daiContract.address,
        params: [address, contractInfo.address]
    });
    calls.push({path: ['balances', 'dai'], method: 'balanceOf', address: daiContract.address, params: [address]});
    calls.push({
        path: ['allowances', 'frax'],
        method: 'allowance',
        address: fraxContract.address,
        params: [address, contractInfo.address]
    });
    calls.push({path: ['balances', 'frax'], method: 'balanceOf', address: fraxContract.address, params: [address]});

    const result = {};

    const hydrateFunc = multicallStandardHydrate(result)
    await multiCallBc(calls, erc20AbiJson, signer, hydrateFunc);

    return result;
}

const _doApprove = async (contractAddress, token, signer, provider) => {
    await _initErc20Contract(signer, provider);
    const contract = token === 'dai' ? daiContract : fraxContract;

    return await callBc(async () => {
        return await contract.approve(contractAddress, allowance);
    });
}

function _defineState(wlState, seedState) {
    let state = SALE_STATE.NOT_STARTED;
    if (wlState === 1) {
        state = SALE_STATE.WL
    } else if (wlState === 2) {
        state = SALE_STATE.ENDED
    } 
    return state;
}

async function _getSeedUserInfo(state, signer, provider, address) {


    return await callBc(async () => {


        let info = await _getSeedWhiteListInfo(state, signer, provider);
        if (typeof (info) === 'string') {
            return info;
        }
        info.canMint = info.allocationStable > 0;
        const tokenUserInfo = await _getTokenUserInfo(provider, signer, seedContractInfo.contract)

        info = {...info, ...tokenUserInfo}

        // info.allowances = await _getAllowances(process.env.REACT_APP_CONTRACT_SEED, signer, provider);
        // info.balances = await _getBalances(signer, provider);

        return {
            userInfo: info,
            address,
        };
    });
}

const _getSeedWhiteListInfo = async (state, signer, provider) => {

    const address = await signer?.getAddress();
    if (!address)
        return;

    const [seedContract,] = await _initContract(signer, provider);
    if (!seedContract)
        return null;

    return await callBc(async () => {

        const [allocationStable, allocationCvg] = await seedContract.getAllocation(address);
        if (allocationStable && allocationCvg) {
            if (state === SALE_STATE.PRE_SEED) {
                const [, isClaimed] = await seedContract.allocationPreseed(address);
                return {allocationStable, allocationCvg, address, isClaimed};
            } else if (state === SALE_STATE.SEED) {
                const [, isClaimed] = await seedContract.allocationSeed(address);
                return {allocationStable, allocationCvg, address, isClaimed};
            }

        }
        return null;
    });
}

const _getVestingInfo = (state) => {

    if (state === SALE_STATE.WL) {
        return getVestingByTYpe('WL')
    }
    if (state === SALE_STATE.SEED || state === SALE_STATE.PRE_SEED) {
        return getVestingByTYpe('SEED')
    }
    return {};

}

const getVestingByTYpe = (type) => {
    const vestings =
        {
            'WL': {
                cliff_percent: .33,
                cliff_length: 0, // 33% au  lancement
                vesting_length: 3, // puis 3 cycle de 30 jours
                vesting_cycle: 30
            },
            'SEED': {
                cliff_percent: .05,
                cliff_length: 45,  // 33%  45 jours après le  lancement
                vesting_length: 10, // puis 10 cycles de 45 jours
                vesting_cycle: 45
            }
        }


    if (['WL', 'SEED'].indexOf(type) !== -1) {
        return vestings[type]
    }
    return {};
}


const _getPresaleAllocationInfo = async (address) => {

    if (!ethers.utils.isAddress(address))
        return {};

    const uri = `${urlApi}/proof/wl/${address}`;
    const response = await fetch(uri, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });
    return (await response.json()) || {listingType: 0, proof: null};

}

async function _getPresaleUserInfo(state, signer, provider, address) {
    return await callBc(async () => {

        const [, wlContract] = await _initContract(signer, provider);
        if (!wlContract)
            return null;

        let info = await _getPresaleWhiteListInfo(signer, provider);
        if (typeof (info) === 'string') {
            return info;
        }
        const tokenUserInfo = await _getTokenUserInfo(provider, signer, wlContract)
        info = {...info, ...tokenUserInfo}
        return {
            userInfo: info,
            address,
        };
    });
}

const _getPresaleWhiteListInfo = async (signer, provider) => {

    const address = await signer?.getAddress();
    if (!address)
        return;

    const [, wlContract] = await _initContract(signer, provider);
    if (!wlContract)
        return null;

    // load White list file
    const {listingType} = await _getPresaleAllocationInfo(address)

    if (['wls', 'wlm', 'wll'].indexOf(listingType) === -1) {
        return {canMint: false}
    } else {
        const type = listingType === 'wls' ? PRESALE_ALLOCATION_TYPE.S : listingType === 'wlm' ? PRESALE_ALLOCATION_TYPE.M : PRESALE_ALLOCATION_TYPE.L;
        const minted = await wlContract.mintersToggle(address);
        return {canMint: true, isClaimed: minted, type, address}
    }
}

const doPresaleApprove = async (token, signer, provider) => {
    return await _doApprove(presaleContractInfo.address, token, signer, provider)
}

const doPresaleMint = async (token, amount, signer, provider) => {

    const address = await signer?.getAddress();
    if (!address)
        return;



    const {proof, listingType} = await _getPresaleAllocationInfo(address)
    let type = PRESALE_ALLOCATION_TYPE.NONE;
    if (listingType) {
        type = listingType === 'wls' ? PRESALE_ALLOCATION_TYPE.S : listingType === 'wlm' ? PRESALE_ALLOCATION_TYPE.M : PRESALE_ALLOCATION_TYPE.L;
    }

    if (type === PRESALE_ALLOCATION_TYPE.NONE || !proof)
        return;

    let amountWei = ethers.utils.parseEther(amount.toString())
    const [, wlContract] = await _initContract(signer, provider);


    // check if the user has already minted
    const minted = await wlContract.mintersToggle(address);
    if (!minted) {
        return await callBc(async () => {
            return await wlContract.investMint(proof, amountWei, token === 'dai', type);
        });
    }


}

const refillToken = async (tokenId, amount, token, signer, provider) => {

    const address = await signer?.getAddress();
    if (!address)
        return;

    let amountWei = ethers.utils.parseEther(amount.toString())
    const [, wlContract] = await _initContract(signer, provider);

    return await callBc(async () => {
        return await wlContract.refillToken(tokenId, amountWei, token === 'dai');
    });


}

const doSeedApprove = async (token, signer, provider) => {
    return await _doApprove(process.env.REACT_APP_CONTRACT_SEED, token, signer, provider)
}

const doSeedMint = async (token, saleState, signer, provider) => {

    if (saleState !== SALE_STATE.PRE_SEED && saleState !== SALE_STATE.SEED) {
        return 'sale is not active';
    }

    return await callBc(async () => {
        const [seedContract,] = await _initContract(signer, provider);
        return await seedContract.investMint(token === 'dai');
    });
}

const getNftInfos = async (signer, provider) => {
    const address =  signer._address;
    if (!address)
        return [];

    const [seed, presale, ibo] = await _initContract(null, provider);
    const tokenIdSeed = await seed.getTokenIdsForWallet(address);
    const tokenIdPresale = await presale.getTokenIdsForWallet(address);
    const tokenIdIbo = await ibo.getTokenIdsForWallet(address);
    const tokenSeed =[];
    let totalSeed = BigNumber.from(0);
    let totalPresale = BigNumber.from(0);
    let totalIbo = BigNumber.from(0);
    for (let index = 0; index < tokenIdSeed.length; index++) {
        const cvgAmount = (await seed.presaleInfoTokenId(tokenIdSeed[index])).cvgAmount;
        tokenSeed.push({cvgAmount : cvgAmount, tokenId : tokenIdSeed[index]});
        totalSeed = totalSeed.add(cvgAmount);
    }

    const tokenPresale =[];
    for (let index = 0; index < tokenIdPresale.length; index++) {
        const cvgAmount = (await presale.presaleInfos(tokenIdPresale[index])).cvgAmount;
        tokenPresale.push({cvgAmount : cvgAmount, tokenId : tokenIdPresale[index]});
        totalPresale = totalPresale.add(cvgAmount);
    }

    const tokenIbo =[];
    for (let index = 0; index < tokenIdIbo.length; index++) {
        const cvgAmount = await ibo.totalCvgPerToken(tokenIdIbo[index]);
        tokenIbo.push({cvgAmount : cvgAmount, tokenId : tokenIdIbo[index]});
        totalIbo = totalIbo.add(cvgAmount);
    }


    return [{type : "Seed", tokens:tokenSeed, totalCvg : totalSeed},{type : "Presale", tokens: tokenPresale, totalCvg : totalPresale} , {type : "IBO", tokens:tokenIbo, totalCvg : totalIbo} ];
}

const getStaticInfos = async (provider) => {


    if (!process.env.REACT_APP_CONTRACT_SEED)
        return {active: false};

    const [seedContract, wlContract] = await _initContract(null, provider);

    if (!seedContract)
        return {active: false};

    const seedState = parseInt(await seedContract.saleState());
    if (!wlContract && seedState === SALE_STATE.ENDED)
        return {active: false};

    let wlState = null;
    if (wlContract)
        wlState = parseInt(await wlContract.saleState());
    const state = _defineState(wlState, seedState);

    const calls = []
    const results = {};
    const hydrateFunc = multicallStandardHydrate(results)

    calls.push({
        path: ['preSeed', 'maxSupply'],
        method: 'MAX_SUPPLY_PRESEED',
        address: seedContractInfo.address,
        params: []
    });
    calls.push({
        path: ['preSeed', 'supply'],
        method: 'totalCvgPreseed',
        address: seedContractInfo.address,
        params: []
    });
    calls.push({
        path: ['seed', 'maxSupply'],
        method: 'MAX_SUPPLY_SEED',
        address: seedContractInfo.address,
        params: []
    });
    calls.push({path: ['seed', 'supply'], method: 'totalCvgSeed', address: seedContractInfo.address, params: []});

    if (presaleContractInfo?.address) {
        calls.push({
            path: ['wl', 'maxSupply'],
            method: 'MAX_SUPPLY_PRESALE',
            address: presaleContractInfo.address,
            abiIndex: 1,
            params: []
        });
        calls.push({
            path: ['wl', 'currentSupply'],
            method: 'supply',
            address: presaleContractInfo.address,
            abiIndex: 1,
            params: []
        });
    }


    if (state === SALE_STATE.PRE_SEED) {
        calls.push({
            path: ['preSeed', 'price'],
            method: 'PRICE_PRESEED',
            address: seedContractInfo.address,
            params: []
        });
        calls.push({
            path: ['preSeed', 'numerator'],
            method: 'NUMERATOR',
            address: seedContractInfo.address,
            params: []
        });
        calls.push({
            path: ['preSeed', 'maxStable'],
            method: 'MAX_STABLE_PRESEED',
            address: seedContractInfo.address,
            params: []
        });
    }

    if (state === SALE_STATE.SEED) {
        calls.push({path: ['seed', 'price'], method: 'PRICE_SEED', address: seedContractInfo.address, params: []});
        calls.push({
            path: ['seed', 'numerator'],
            method: 'NUMERATOR',
            address: seedContractInfo.address,
            params: []
        });
        calls.push({
            path: ['seed', 'maxStable'],
            method: 'MAX_STABLE_SEED',
            address: seedContractInfo.address,
            params: []
        });
    }

    if (state === SALE_STATE.WL) {
        calls.push({
            path: ['wl', 'supply'],
            method: 'supply',
            address: presaleContractInfo.address,
            abiIndex: 1,
            params: []
        });
        calls.push({
            path: ['wl', 'price'],
            method: 'PRICE_WL',
            address: presaleContractInfo.address,
            abiIndex: 1,
            params: []
        });
        calls.push({
            path: ['wl', 'numerator'],
            method: 'NUMERATOR',
            address: presaleContractInfo.address,
            abiIndex: 1,
            params: []
        });
        calls.push({
            path: ['wl', 'types', 2],
            method: 'wlParams',
            address: presaleContractInfo.address,
            abiIndex: 1,
            params: [2]
        });
        calls.push({
            path: ['wl', 'types', 3],
            method: 'wlParams',
            address: presaleContractInfo.address,
            abiIndex: 1,
            params: [3]
        });
        calls.push({
            path: ['wl', 'types', 4],
            method: 'wlParams',
            address: presaleContractInfo.address,
            abiIndex: 1,
            params: [4]
        });


    }

    await multiCallBc(calls, [seedContractInfo.abi, presaleContractInfo.abi], provider, hydrateFunc);

    if (state === SALE_STATE.WL) {
        const [, wlContract] = await _initContract(null, provider);
        const params2 = await wlContract.wlParams(2);
        results.wl.types[2] = {...params2, min: params2.minInvest, max: params2.maxInvest, id: 'S'};
        const params3 = await wlContract.wlParams(3);
        results.wl.types[3] = {...params3, min: params3.minInvest, max: params3.maxInvest, id: 'M'};
        const params4 = await wlContract.wlParams(4);
        results.wl.types[4] = {...params4, min: params4.minInvest, max: params4.maxInvest, id: 'L'};
    }


    if (results.wl?.maxSupply && results.wl?.currentSupply)
        results.wl.supply = results.wl.maxSupply.sub(results.wl.currentSupply);

    if (results.preSeed?.price)
        results.preSeed.price = results?.preSeed.price && results?.preSeed.numerator ? results?.preSeed.price / results?.preSeed.numerator : '-';
    if (results.seed?.price)
        results.seed.price = results?.seed.price && results?.seed.numerator ? results?.seed.price / results?.seed.numerator : '-';
    if (results.wl?.price) {
        results.wl.price = results?.wl.price && results?.wl.numerator ? results?.wl.price / results?.wl.numerator : '-';
        results.wl.types[2] = {...results.wl.types[2], ...{id: 'S'}};
        results.wl.types[3] = {...results.wl.types[3], ...{id: 'M'}};
        results.wl.types[4] = {...results.wl.types[4], ...{id: 'L'}};
        results.wl.vesting = _getVestingInfo(state);
    }

    return {
        active: (state === SALE_STATE.SEED || state === SALE_STATE.PRE_SEED || state === SALE_STATE.WL),
        saleState: state,
        info: results
    };

};

const getUserInfo = async (state, signer, provider) => {

    if (!process.env.REACT_APP_CONTRACT_SEED)
        return {active: false};

    const address = await signer?.getAddress();

    if (state === SALE_STATE.SEED || state === SALE_STATE.PRE_SEED)
        return await _getSeedUserInfo(state, signer, provider, address)

    if (state === SALE_STATE.WL)
        return await _getPresaleUserInfo(state, signer, provider, address)

    return new Promise({resolve: () => undefined, reject: () => undefined})
}

export {
    doSeedMint,
    doPresaleMint,
    doSeedApprove,
    doPresaleApprove,
    getUserInfo,
    getStaticInfos,
    getNftInfos,
    getVestingByTYpe,
    refillToken
} ;







