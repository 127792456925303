import avatarImage from '../assets/avatar.svg'

const CustomTheme = {
    colors: {
        accentColor: '',
        accentColorForeground: '...',
        actionButtonBorder: '...',
        actionButtonBorderMobile: '...',
        actionButtonSecondaryBackground: '...',
        closeButton: '...',
        closeButtonBackground: '...',
        connectButtonBackground: '',
        connectButtonBackgroundError: '...',
        connectButtonInnerBackground: '',
        connectButtonText: '',
        connectButtonTextError: '...',
        connectionIndicator: '...',
        error: '...',
        generalBorder: '...',
        generalBorderDim: '...',
        menuItemBackground: '...',
        modalBackdrop: '...',
        modalBackground: '#fff',
        modalBorder: '...',
        modalText: '...',
        modalTextDim: '...',
        modalTextSecondary: '...',
        profileAction: '...',
        profileActionHover: '...',
        profileForeground: '...',
        selectedOptionBorder: '...',
        standby: '...',
    },
    fonts: {
        body: '...',
    },
    radii: {
        actionButton: '...',
        connectButton: '',
        menuButton: '...',
        modal: '...',
        modalMobile: '...',
    },
    shadows: {
        connectButton: '...',
        dialog: '...',
        profileDetailsAction: '...',
        selectedOption: '...',
        selectedWallet: '...',
        walletLogo: '...',
    },
};

const Disclaimer = ({Text, Link}) => (
    <Text>
        {/* By connecting your wallet, you agree to the{' '}
        <Link href="https://termsofservice.xyz">Terms of Service</Link> and
        acknowledge you have read and understand the protocol{' '}
        <Link href="https://disclaimer.xyz">Disclaimer</Link> */}
    </Text>
);


const  CustomAvatar = ( )=> <img className="inline-block" src={avatarImage} alt="Convergence"/> ;


export {CustomTheme, Disclaimer,CustomAvatar};