import { useContext } from 'react';

import { BigNumber } from 'ethers';
import { IboContext } from '../../context/IboContext';
import ApproveBtn from '../ApproveBtn';

function BondListActionButton({bondInfos}) {
    const { handleDeposit, handleApprove, iboInfos } = useContext(IboContext);


    const tokenName = bondInfos.staticData.token.token;
    const balanceFrom = iboInfos.balanceAllowance[tokenName].balanceOf;
    const allowance = iboInfos.balanceAllowance[tokenName].allowance;
    return (
        <>
            <ApproveBtn currentAllowance={allowance} handleApprove={() => handleApprove(tokenName)} ></ApproveBtn>
            <button
                type="button"
                disabled={balanceFrom.gt(BigNumber.from('0')) ? false : true}
                className="Btn vertical-align-top "
                onClick={(e) => handleDeposit(tokenName)}
            >
                Deposit
            </button>
        </>
    );
}

export default BondListActionButton;
