import { parse } from 'css-what';
import { BigNumber, ethers, utils } from 'ethers';
import { formatEther, formatUnits, parseEther, parseUnits } from 'ethers/lib/utils';

const dropAtLaunch = 0;

export const GET_BOND_PARAMS_LOADING =  "GET_BOND_PARAMS_LOADING";
export const GET_BOND_PARAMS_LOADED = "GET_BOND_PARAMS_LOADED";
export const GET_BOND_PARAMS_ERROR = "GET_BOND_PARAMS_ERROR";

export const TOGGLE_DROPDOWN = "TOGGLE_DROPDOWN";
export const UPDATE_VALUE_UNDERLAYING = "UPDATE_VALUE_UNDERLAYING";
export const UPDATE_VALUE_CVG = "UPDATE_VALUE_CVG";

export const UPDATE_BOND_DROPDOWN = "UPDATE_BOND_DROPDOWN";
export const UPDATE_SLIPPAGE = "UPDATE_SLIPPAGE";

export const UPDATE_ONE_BOND_LOADING = "UPDATE_ONE_BOND_LOADING";
export const UPDATE_ONE_BOND_LOADED  = "UPDATE_ONE_BOND_LOADED";
export const UPDATE_ONE_BOND_ERROR   = "UPDATE_ONE_BOND_ERROR";


export const GET_PROOF_LOADING = "GET_PROOF_LOADING";
export const GET_PROOF_LOADED  = "GET_PROOF_LOADED";
export const GET_PROOF_ERROR   = "GET_PROOF_ERROR";

export const UPDATE_MAX_CVG = "UPDATE_MAX_CVG";
export const RELOAD_GLOBAL_DATA = "RELOAD_GLOBAL_DATA";

export const GET_RELOADED_DATA_LOADING = "GET_RELOADED_DATA_LOADING";
export const GET_RELOADED_DATA_LOADED = "GET_RELOADED_DATA_LOADED";
export const GET_RELOADED_DATA_ERROR = "GET_RELOADED_DATA_ERROR";


export function iboReducer(state, action) {

    switch (action.type) {

        case GET_BOND_PARAMS_LOADING: {
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    loading: true,
                },
                objectStates: [...state.objectStates, action.type],
            };
        }

        case GET_BOND_PARAMS_LOADED: {
            const mintingOption = {"code" : 0, "name" : "MINT"};
            let activeDropdownConfig = mintingOption;
            let biggestPositionValue = BigNumber.from(0);
            let nftDropdownConfig =[];
            if(action?.data?.nftParams?.tokenData){
                nftDropdownConfig = Object.entries(action?.data?.nftParams?.tokenData).map(([tokenId, totalVested]) => {
                    const newOption = {
                        "code" : tokenId,
                        "name" : "Token "+tokenId
                    };
                    if(biggestPositionValue.lte(totalVested) ) {
                        biggestPositionValue = totalVested;
                        activeDropdownConfig = newOption;
                    }
                    return newOption;
                }); 
            }

            nftDropdownConfig.unshift(mintingOption);

            Object.entries(action.data.bondParams).forEach(([k,v]) => {
                const graphData = updateGraphData([], 0, Number(formatEther(biggestPositionValue)) , activeDropdownConfig.code === 0);

                const newValue = {
                    staticData : v, 
                    activeData : {
                        inputValueUnderlaying : 0,
                        inputValueCvg : 0,
                        nftDropdownConfig : nftDropdownConfig,
                        activeDropdownConfig : activeDropdownConfig,
                        activeTotalCvg : biggestPositionValue,
                        isExpanded:false,
                        activeSlippage : { label: '0.1%', code: 0.001 },
                        graphData : graphData
                    }
                };
                action.data.bondParams[k] = newValue;
            });
            return {
                ...state,
                bondsData: action.data.bondParams,
                balanceAllowance: action.data.balanceAllowance,
                actualTimestamp : action.data.actualTimestamp,
                isPrivilege : action.data.isPrivilege,
                iboGlobalData : action.data.iboGlobalData,
                nftParams: action.data.nftParams,
                objectStates: [...state.objectStates, action.type],
            };
        }

        case GET_BOND_PARAMS_ERROR: {
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    error: true,
                },
                objectStates: [...state.objectStates, action.type],
            };
        }


        case TOGGLE_DROPDOWN: {
            return{
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.bondName] : {
                        ...state.bondsData[action.bondName],
                        activeData : {
                            ...state.bondsData[action.bondName].activeData,
                            isExpanded : !state.bondsData[action.bondName].activeData.isExpanded
                        }  
                    }
                },
            };
        }

        case UPDATE_VALUE_UNDERLAYING : {
            const value = action.data.value ? action.data.value : 0;
            
            const cvgValue = Number(formatUnits(parseUnits(value.toString(), 18).mul(parseUnits("1" , 18)).div(state.bondsData[action.data.bondName].staticData.bondPriceAsset)));
            const isMint = state.bondsData[action.data.bondName].activeData.activeDropdownConfig.code === 0 ;

            const graphDataCopy = [...state.bondsData[action.data.bondName].activeData.graphData];
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.data.bondName] : {
                        ...state.bondsData[action.data.bondName],
                        activeData : {
                            ...state.bondsData[action.data.bondName].activeData,
                            inputValueUnderlaying : value,
                            inputValueCvg : cvgValue,
                            graphData : updateGraphData(
                                isMint ? [] : graphDataCopy, 
                                cvgValue,  
                                isMint ? 0 :  Number(formatEther(state.bondsData[action.data.bondName].activeData.activeTotalCvg)) , 
                                isMint
                            )

                        }  
                    }
                },
            };
        }

        case UPDATE_VALUE_CVG : {
            const value = action.data.value ? action.data.value : 0;
            const graphDataCopy = [...state.bondsData[action.data.bondName].activeData.graphData];
            const isMint = state.bondsData[action.data.bondName].activeData.activeDropdownConfig.code === 0 ;

            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.data.bondName] : {
                        ...state.bondsData[action.data.bondName],
                        activeData : {
                            ...state.bondsData[action.data.bondName].activeData,
                            inputValueUnderlaying : Number(formatUnits(parseUnits(value.toString(), 18).mul(state.bondsData[action.data.bondName].staticData.bondPriceAsset).div(parseUnits("1" , 18)), 18) ),
                            inputValueCvg : value,
                            graphData : updateGraphData(
                                isMint ? [] : graphDataCopy, 
                                value,  
                                isMint ? 0 :  Number(formatEther(state.bondsData[action.data.bondName].activeData.activeTotalCvg)) , 
                                isMint
                            )
                        }  
                    }
                },
            };
        }

        case UPDATE_SLIPPAGE : {
            const value = action.data.value;
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.data.bondName] : {
                        ...state.bondsData[action.data.bondName],
                        activeData : {
                            ...state.bondsData[action.data.bondName].activeData,
                            activeSlippage : value
                        }  
                    }
                },
            };
        }

        case UPDATE_BOND_DROPDOWN : {
            const graphDataCopy = [...state.bondsData[action.data.bondName].activeData.graphData];

            const value = action.data.value;
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.data.bondName] : {
                        ...state.bondsData[action.data.bondName],
                        activeData : {
                            ...state.bondsData[action.data.bondName].activeData,
                            activeDropdownConfig : value,
                            inputValueUnderlaying : 0,
                            inputValueCvg : 0,
                            graphData : updateGraphData(
                                [], 
                                0,  
                                Number(formatEther(!state.nftParams.tokenData[action.data.value.code]  ? BigNumber.from(0) : state.nftParams.tokenData[action.data.value.code]))  , 
                                action.data.value.code === 0
                            ),
                            activeTotalCvg :  state.nftParams.tokenData[action.data.value.code],

                        }  
                    }
                },
            };
        }

        case UPDATE_ONE_BOND_LOADING : {
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.bondName] : {
                        ...state.bondsData[action.bondName],
                        loading:true

                    }
                },
            };
        }

        case UPDATE_ONE_BOND_LOADED : {
            const mintingOption = {"code" : 0, "name" : "MINT"};
            let activeDropdownConfig = mintingOption;
            let biggestPositionValue = BigNumber.from(0);
            let nftDropdownConfig =[];
            if(action?.payload?.nftParams?.tokenData){
                nftDropdownConfig = Object.entries(action?.payload?.nftParams?.tokenData).map(([tokenId, totalVested]) => {
                    const newOption = {
                        "code" : tokenId,
                        "name" : "Token "+tokenId
                    };
                    if(biggestPositionValue.lte(totalVested) ) {
                        biggestPositionValue = totalVested;
                        activeDropdownConfig = newOption;
                    }
                    return newOption;
                }); 
            }

            nftDropdownConfig.unshift(mintingOption);

            const graphData = updateGraphData([], 0, Number(formatEther(biggestPositionValue)) , activeDropdownConfig.code === 0);

            const newValue = {
                staticData : action.payload.bondParams, 
                activeData : {
                    inputValueUnderlaying : 0,
                    inputValueCvg : 0,
                    nftDropdownConfig : nftDropdownConfig,
                    activeDropdownConfig : activeDropdownConfig,
                    activeTotalCvg : biggestPositionValue,
                    isExpanded: true,
                    activeSlippage : { label: '0.1%', code: 0.001 },
                    graphData : graphData
                }
            };
            action.payload.bondParams = newValue;
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.bondName] : {
                        ...action.payload.bondParams,
                        loading: false 
                    }
                },
                balanceAllowance: {
                    ...state.balanceAllowance,
                    [action.bondName] : action.payload.balanceAllowance
                },
                nftParams:action.payload.nftParams
            };
        }

        case UPDATE_ONE_BOND_ERROR : {
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.bondName] : {
                        ...state.bondsData[action.bondName],
                        activeData : {
                            ...state.bondsData[action.bondName].activeData,
                        }  
                    }
                },
            };
        }
        
        case GET_PROOF_LOADING : {
            return {
                ...state,
                objectStates: [...state.objectStates, action.type],

            };
        }

        case GET_PROOF_LOADED : {
            return {
                ...state,
                merkleData: action.data,
                objectStates: [...state.objectStates, action.type],

            };
        }
        case UPDATE_MAX_CVG : {
            const tokenName = action.data.bondName;
            const balance = state.balanceAllowance[tokenName].balanceOf;
            const percentageMaxToMint = state.bondsData[tokenName].staticData.percentageMaxCvgToMint; 
            const isPrivilege = state.isPrivilege;
            const soldDuringPrivilege = state.iboGlobalData.soldDuringPrivilege;
            const maxCvgToMint = state.bondsData[tokenName].staticData.maxCvgToMint;
            const graphDataCopy = [...state.bondsData[action.data.bondName].activeData.graphData];
            const price = state.bondsData[action.data.bondName].staticData.bondPriceAsset;
            let maxAmount;
            let maxCvgToMintInOneTx = maxCvgToMint.mul(percentageMaxToMint).div(BigNumber.from(1000));
            if(isPrivilege) {
                if(state.merkleData.listingType === 'wl'){
                    maxAmount = utils.parseEther("7500").sub(soldDuringPrivilege);
                }
                else {
                    maxAmount = utils.parseEther("15000").sub(soldDuringPrivilege);
                }
                maxAmount = maxAmount.gte(balance) ? balance : maxAmount;
            }
            else {
                maxAmount = maxCvgToMintInOneTx.gte(balance) ? balance.mul(parseEther("1")).div(price) : maxCvgToMintInOneTx;
            }
            const underlayingAmount = (maxAmount.mul(price).div(parseEther("1"))).mul(99_999).div(BigNumber.from(100_000));

            maxAmount = underlayingAmount.mul(parseEther("1")).div(price);
            return {
                ...state,
                bondsData: {
                    ...state.bondsData,
                    [action.data.bondName] : {
                        ...state.bondsData[action.data.bondName],
                        activeData : {
                            ...state.bondsData[action.data.bondName].activeData,
                            inputValueUnderlaying : Number(formatUnits(underlayingAmount,18)),
                            inputValueCvg : Number(formatUnits(maxAmount, 18)),
                            graphData : updateGraphData(
                                graphDataCopy, 
                                Number(formatUnits(maxAmount, 18)),  
                                state.bondsData[action.data.bondName].activeData.activeDropdownConfig.code === 0 ? 0 : Number(formatEther(state.bondsData[action.data.bondName].activeData.activeTotalCvg)) , 
                                state.bondsData[action.data.bondName].activeData.activeDropdownConfig.code === 0
                            )
                        }  
                    }
                },
            };
        }


        case GET_RELOADED_DATA_LOADING: {
            return {
                ...state
            };
        }


        case GET_RELOADED_DATA_LOADED: {
            Object.entries(action.data.bondParams).forEach(([k,v]) => {
                action.data.bondParams[k] = {
                    staticData : v,
                    activeData : state.bondsData[k].activeData
                };
            });
            return {
                ...state,
                bondsData: action.data.bondParams,
                iboGlobalData : action.data.iboGlobalData
            };
        }


        case GET_RELOADED_DATA_ERROR: {
            return {
                ...state
            };
        }

        default: {
            break;
        }
    }
}

function updateGraphData(graphData, cvgValueToAdd, cvgValueOnToken, isMint){

    if(isMint){
        if(cvgValueToAdd === 0){
            graphData = [];
        }
        else{
            const launchPoint = {moment :'Launch', cvgValue : cvgValueToAdd * dropAtLaunch};
            const middlePoint = {moment :'L+1M', cvgValue : (cvgValueToAdd * dropAtLaunch + cvgValueToAdd) / 2};
            const endPoint = {moment :'L+2M', cvgValue : cvgValueToAdd };
            graphData = [launchPoint, middlePoint, endPoint];
        }
    }
    else {
        const launchPoint = {
            moment :'Launch', 
            cvgValue : (cvgValueOnToken * dropAtLaunch ), 
            cvgValueModified : (cvgValueToAdd * dropAtLaunch ) + (cvgValueOnToken * dropAtLaunch )
        };
        const middlePoint = {
            moment :'L+1M', 
            cvgValue :  (cvgValueOnToken * dropAtLaunch + cvgValueOnToken) / 2, 
            cvgValueModified : (cvgValueToAdd * dropAtLaunch + cvgValueToAdd + cvgValueOnToken + (cvgValueOnToken * dropAtLaunch )) / 2
        };
        const endPoint = {
            moment :'L+2M', 
            cvgValue : cvgValueOnToken, 
            cvgValueModified : cvgValueToAdd + cvgValueOnToken 
        };
        graphData = [launchPoint, middlePoint, endPoint];
    }
    return graphData;
}


