import loader from "../assets/loader.gif";


function  Loader(){


    return (

            <>
            <img alt="Waiting.." src={loader} style={{
                width: "3rem",
                height: "auto",
                verticalAlign: "middle",
                filter: "hue-rotate(66deg)"
            }}/>

            </>
    )
}

export  default Loader;

