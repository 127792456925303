import {Dialog} from "primereact/dialog";
import {useState} from "react";

function DisclaimerDialog({displayBasic, setDisplayBasic}) {


    return (
        <Dialog header="Disclaimer" visible={displayBasic} style={{width: '70vw', height: '90vh'}}
                onHide={() => setDisplayBasic(false)}  breakpoints={{ '640px': '90vw'}}
                modal={true} blockScroll={true}>


            <div className=" p-2 Disclaimer md:px-5">
                <h1>CVG PRE-SALE </h1>

                <p className="p-message">
                    Please read this Agreement set out herein carefully before clicking the <b>“I AGREE”</b> button on
                    the designated web address.

                </p>

                <p className="font-bold mb-2">BE AWARE THAT YOUR PURCHASE OF TOKENS IS AT YOUR OWN RISK. IF YOU ARE
                    LISTED IN
                    ANY OF THE LISTS DESCRIBED BELOW, AND DESPITE THAT, YOU DECIDE TO PROCEED WITH THE PURCHASE WITHOUT
                    ADHERING TO THESE TERMS, NEITHER THE DEVELOPMENT COMPANY BEHIND THE CONVERGENCE PROTOCOL (the
                    “COMPANY”) NOR THE CONVERGENCE DAO (the “DAO”) WILL BE HELD RESPONSIBLE IF YOU ACQUIRE THE TOKENS
                    WHILE BEING SUBJECT TO THE RESTRICTIONS.</p>

                <p className="font-bold mb-2">BY ACCEPTING THIS DISCLAIMER, YOU ACKNOWLEDGE THAT THE DEVELOPMENT COMPANY
                    AND
                    THE DAO FULLY DISCLAIM ANY RESPONSIBILITY AND LIABILITY ASSOCIATED WITH YOUR TOKEN PURCHASE,
                    ESPECIALLY IF IT IS MADE IN VIOLATION OF THESE TERMS. IT IS OF UTMOST IMPORTANCE THAT YOU CAREFULLY
                    REVIEW AND UNDERSTAND THE LISTS AND THESE TERMS PROVIDED BEFORE PROCEEDING WITH THE TOKEN
                    PURCHASE.</p>

                <p className="font-bold mb-2">THE OFFER AND SALE OF TOKENS DESCRIBED HEREIN HAVE NOT BEEN REGISTERED
                    UNDER
                    THE U.S. SECURITIES ACT OF 1933, AS AMENDED (THE "SECURITIES ACT"), OR UNDER THE SECURITIES LAWS OF
                    ANY STATE OR FOREIGN JURISDICTION. THE TOKENS ARE NOT INTENDED TO CONSTITUTE SECURITIES OF ANY FORM,
                    UNITS IN A BUSINESS TRUST, UNITS IN A COLLECTIVE INVESTMENT SCHEME, CAPITAL MARKETS PRODUCTS, OR ANY
                    OTHER FORM OF REGULATED INVESTMENT OR INVESTMENT PRODUCT IN ANY JURISDICTION. THUS, IF YOU DECIDE TO
                    PURCHASE TOKENS WITHOUT ADHERING TO THESE SPECIFIED CONDITIONS AND RESTRICTIONS, NEITHER THE
                    DEVELOPMENT COMPANY BEHIND THE CONVERGENCE PROTOCOL NOR THE DAO WILL BE HELD RESPONSIBLE FOR ANY
                    CONSEQUENCES RESULTING FROM NON-COMPLIANCE WITH THE SECURITIES ACT OR OTHER RELEVANT SECURITIES
                    LAWS. IT IS YOUR RESPONSIBILITY TO ENSURE COMPLIANCE WITH ALL RELEVANT RESTRICTIONS, AND NEITHER THE
                    DEVELOPMENT COMPANY NOR THE DAO SHALL BE LIABLE FOR ANY CONSEQUENCES RESULTING FROM NON-COMPLIANCE
                    WITH THESE TERMS.</p>

                <p className="font-bold mb-2">IF YOU ARE IN ANY DOUBTS TO THE ACTION YOU SHOULD TAKE, YOU SHOULD CONSULT
                    YOUR
                    LEGAL, FINANCIAL, TAX OR OTHER PROFESSIONAL ADVISOR(S). IF YOU DO NOT AGREE TO THIS AGREEMENT,
                    PLEASE DO NOT ACCEPT THIS AGREEMENT OR ACQUIRE ANY TOKENS.</p>


                <h2>General Notice - Eligibility</h2>
                <p className="mb-2">The CVG token is not available to residents of all countries. If you are a resident
                    of a country where the CVG token is not available, you should not participate in the sale of the CVG
                    token.
                </p>
                <p className="mb-2">
                    By taking part in the pre-sale,
                    <b>you declare and warrant that you are not a resident of a country or jurisdiction where offering
                        the CVG token is prohibited, restricted, or unauthorized. </b> If you are uncertain about the
                    legality of participating in the pre-sale in your country, it is advisable to seek guidance from a
                    legal professional before proceeding.
                </p>
                <p className="mb-2">
                    You are not eligible and You are not to acquisition any tokens in the Token Issuance if:

                </p>
                <ul>
                    <li>
                        where You are an individual, You are a citizen, domiciled in, resident of, or physically present
                        / located in an Excluded Jurisdiction;
                    </li>
                    <li>
                        you are a body corporate:
                        <br/>(i) which is incorporated in, or operates out of, an Excluded Jurisdiction or
                        <br/> (ii) which is under the control of one (1) or more individuals who is/are citizens of,
                        domiciled in. or residents of, or physically present / located in, an Excluded Jurisdiction;
                    </li>
                    <li>
                        you are an individual or body corporate:
                        <br/> (i) included in the Consolidated List published by the United Nations Security Council of
                        individuals or entities subject to measures imposed by the United Nations Security Council
                        accessible at <a href="https://www.un.org/securitycouncil/content/un-sc-consolidated-list" className='color-tonic underline'>https://www.un.org/securitycouncil/content/un-sc-consolidated-list</a>; or
                        <br/>(ii) included in the United Nations List (UN Lists) or within the ambit of regulations
                        relating to or implementing United Nations Security Council Resolutions listed by MAS an
                        accessible by {" "}
                        <a href="https://www.mas.gov.sg/regulation/anti-money-laundering/targeted-financial-sanctions/regulations-for-targeted-financial-sanctions" className='color-tonic underline'>https://www.mas.gov.sg/regulation/anti-money-laundering/targeted-financial-sanctions/regulations-for-targeted-financial-sanctions</a>
                        {" "}and{" "} 
                        <a href="https://www.mas.gov.sg/regulation/anti-money-laundering/targeted-financial-sanctions/lists-of-designated-individuals-and-entities" className='color-tonic underline'>https://www.mas.gov.sg/regulation/anti-money-laundering/targeted-financial-sanctions/lists-of-designated-individuals-and-entities</a>
                        {" "}or included on the OFAC sanctions list and database assessible at{" "}
                        <a href="https://sanctionssearch.ofac.treas.gov/" className='color-tonic underline'>https://sanctionssearch.ofac.treas.gov/</a> whether you are an individual or body corporate either
                        expressly included or being a citizen or resident thereof any state thereon or having any
                        interaction or association with any OFAC sanctioned entity or state; or
                    </li>
                    <li>
                        you are an individual or body corporate who is otherwise prohibited or ineligible in any way,
                        where in full or in part, under any law applicable to such individual or body corporate from
                        participating in any part of the transactions contemplated in this Agreement, or

                    </li>
                    <li>
                        the Token Issuance is prohibited, restricted or unauthorized in any form or manner whether in
                        full or in part under the laws, regulatory requirements or rules in any jurisdiction applicable
                        to You.(collectively, the “Disqualified Persons”)
                    </li>
                </ul>
                <h2>
                    Special Notice
                </h2>
                <h3>Residents of Canada
                </h3>
                <p>
                    Unless permitted under securities legislation, the holder of the rights hereunder must not trade the
                    rights hereunder before the date that the issuer becomes a reporting issuer in any province or
                    territory.
                </p>
                <h3>Residents of China
                </h3>
                <p>This offer is not available in mainland China, except for:
                    the Hong Kong and Macau Special Administrative Regions and Taiwan.
                    If you are a resident of mainland China and receive this document, you should not participate in the
                    pre-sale, directly or indirectly, unless you are an authorized distributor or have received specific
                    authorization from the issuer.
                </p>
                <h3>Residents of United Kingdom</h3>
                <p>This document is not approved by an authorized person. It is intended for and directed only at
                    persons who are "relevant persons" as defined in Article 50(1) of the Financial Services and Markets
                    Act 2000 (FPO). Any investment activity to which this document relates will be engaged only with
                    relevant persons. Persons who are not relevant persons should not make any investment decision based
                    on this document or rely on it. Your receipt and retention of this document are subject to you
                    warranting to the sellers, their directors, and their officers that you are a relevant person.
                </p>
                <h3>Residents of Switzerland
                </h3>
                <p>The information presented in this document is intended exclusively for qualified investors as defined
                    by the Swiss Collective Investment Schemes Act ("CISA"), its implementing ordinance, and regulatory
                    guidance. This document is not authorized or approved by any Swiss regulatory authority.
                    This document:
                    <ul>
                        <li>Does not constitute an offer to subscribe for, purchase, or acquire any tokens.</li>
                        
                        <li>Does not serve as a prospectus under the CISA, Swiss Code of Obligations, or the listing
                            rules of any trading venue in Switzerland.
                            Participation in any investment related to this document is exclusively available to
                            qualified investors, and any investment activity will only be engaged with qualified
                            investors. Potential investors must thoroughly assess the risks associated with this
                            investment and seek independent professional advice before making any investment decisions.
                        </li>
                    </ul>
                </p>
                <h3>Residents of Hong Kong
                </h3>
                <p>This document has not been approved by the Securities and Futures Commission (SFC) of Hong Kong. In
                    accordance with the current laws and the SFC's guidance, crypto pre-sales are considered offers of
                    securities and must be regulated accordingly. As a result, residents of Hong Kong are not permitted
                    to acquire CVG tokens. Please be aware that this document does not constitute an invitation to
                    contract or participate in the offering of CVG tokens. It is strongly advised to exercise extreme
                    caution and seek independent professional advice before making any investment decisions. This
                    offering is not intended for the public in Hong Kong and does not constitute securities under the
                    prevailing laws.
                </p>

                <h3>Residents of South Korea - North Korea
                </h3>
                <h4>
                    South Korea:

                </h4>
                <p>This offer is not, and under no circumstances shall be construed as, an offering of securities in
                    South Korea under the Financial Investment Services and Capital Markets Act of South Korea (the
                    "FISMA"). For the purpose of this notice, the term "offering" concerning any securities under FISMA
                    refers to the invitation of subscription for newly issued securities to more than 50 retail
                    investors. Please be aware that this security has not been registered under the FISMA, and,
                    therefore, it may not be marketed, sold, transferred, pledged, or collateralized, either directly or
                    indirectly. Additionally, it is not permitted to be offered or sold to any individual for
                    re-offering or resale, directly or indirectly, in South Korea or to any resident of South Korea.
                </p>
                <h4>North Korea:</h4>
                <p>This agreement is not, and under no circumstances shall be considered as, a securities offering in
                    North Korea. The offer described herein is not intended for the residents of North Korea, and it is
                    not subject to registration or compliance under the securities regulations of North Korea.
                    Similarly, for the purpose of this notification, the term "offering" with respect to any securities
                    does not include an invitation of subscription for newly issued securities to any residents of North
                    Korea. Please be aware that this security has not been authorized, and it is strictly prohibited to
                    be marketed, sold, transferred, pledged, or hypothecated, directly or indirectly, in North Korea or
                    to any resident of North Korea.
                </p>
                <h3>Residents of Australia
                </h3>
                <p>This offer of CVG tokens has not been reviewed, filed, or approved by the Australian Securities and
                    Investments Commission (ASIC). Furthermore, this offer is not available in Australia and qualifies
                    as an exempted disclosure offer under Chapter 6D/7 of the Corporations Act. However, eligible
                    "sophisticated investors" as defined in Section 708(8) of the Corporations Act or "professional
                    investors" as defined in the subsequent Section 708(11) are allowed to participate. The issuer of
                    this document is not registered as a managed investment scheme under the Corporations Act.
                </p>
                <h3>Residents of Israel</h3>
                <p>The offering presented in this document is not available or regulated in Israel. As such, it has not
                    been verified, approved, or authorized by the Israeli authorities. This document is for
                    informational purposes only and does not constitute an offer to sell, purchase, or exchange
                    securities or any other financial instruments in connection with a public offering in Israel, as
                    defined in Annex 1 of the Israeli Securities Law.
                    Investors residing in Israel or considering investments in Israel should refer to local sources of
                    information and consult qualified financial and legal advisors to understand the specific regulatory
                    restrictions and implications applicable to their circumstances.</p>

                <h3>Residents of Russia</h3>
                <p>In no event shall this offering apply to Russia and Russian residents, as well as Russian entities,
                    are not permitted to buy, sell, exchange, or transfer the tokens. The only exceptions are natural
                    and legal persons who are “qualified investors” in accordance with the Federal Law of the Russian
                    Federation on Securities. If you are a Russian resident or a Russian entity and you wish to
                    participate in the presale, you must first consult with an attorney to ensure that you meet the
                    definition of a “qualified investor”.</p>

                <h3>Residents of Cuba</h3>
                <p>This offer is not available to residents of Cuba. This document does not constitute an offering of
                    securities or any other financial instruments in Cuba, and it is not intended for distribution or
                    use by residents of Cuba.</p>

                <h3>Residents of Iran</h3>
                <p>This offer is not available to residents of Iran. This document does not constitute an offering of
                    securities or any other financial instruments in Iran, and it is not intended for distribution or
                    use by residents of Iran.</p>

                <h3>Residents of Syria - Residents of Crimea Region</h3>
                <p>This offer is not available to residents of Syria or the Crimea Region. This document does not
                    constitute an offering of securities or any other financial instruments in Syria or the Crimea
                    Region, and it is not intended for distribution or use by residents of these areas.</p>


                <h2>Acknowledgment and Acceptance </h2>
                <p>
                    By accepting this disclaimer, you acknowledge and fully agree to the terms and conditions
                    set forth above regarding the purchase of CVG tokens during the pre-sale. You understand that
                    <b>acquiring CVG tokens is subject to legal and regulatory restrictions in certain jurisdictions</b>, and
                    non-compliance with these restrictions may result in legal and financial consequences. It is <b>your
                    responsibility to ensure that you are authorized to participate</b> in the CVG token pre-sale in
                    accordance with the laws and regulations applicable in your country of residence. We strongly
                    recommend that you seek advice from a legal or financial professional before making any investment
                    decisions. By accepting this disclaimer, you also declare that you are a qualified, eligible, and
                    authorized investor to participate in the CVG token pre-sale. If you do not agree with these
                    conditions, please do not accept this disclaimer and do not acquire CVG tokens.

                </p>
            </div>
        </Dialog>


    )

}


export default DisclaimerDialog;