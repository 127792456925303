import React, {useContext, useEffect, useReducer, useState} from 'react';
import {useAccount, useProvider, useSigner} from "wagmi";
import NotifyContext from "./NotifyContext";
import { GET_BOND_PARAMS_ERROR, GET_BOND_PARAMS_LOADED, GET_BOND_PARAMS_LOADING, GET_PROOF_ERROR, GET_PROOF_LOADED, GET_PROOF_LOADING, GET_RELOADED_DATA_ERROR, GET_RELOADED_DATA_ERRROR, GET_RELOADED_DATA_LOADED, GET_RELOADED_DATA_LOADING, UPDATE_ONE_BOND_ERROR, UPDATE_ONE_BOND_LOADED, UPDATE_ONE_BOND_LOADING, iboReducer } from './ibo.reducer';
import iboService from "../actions/ibo.service"
import {  BigNumber, Contract, constants, ethers } from 'ethers';
import erc20 from "../actions/erc20.json";
import ibo from "../actions/ibo.json";

import { callBc } from '../actions/utils';
import { formatEther, parseEther } from 'ethers/lib/utils';

const IboContext = React.createContext(null);
const IboProvider = ({children}) => {
    const [addressConnected, setAddressConnected]= useState(undefined);
    const provider = useProvider();
    const {address} = useAccount();
    const {data: signer} = useSigner();

    const {notifyError, notifyBeginTransaction, notifyEndTransaction} = useContext(NotifyContext);

    const INITIAL_SCREEN_TOKEMAK_DATA = {
        isIboActive : false,
        bondsData : {},
        objectStates: [],
    };

    const [iboInfos, dispatchIboInfos] = useReducer(iboReducer, INITIAL_SCREEN_TOKEMAK_DATA);

    const check = [iboInfos?.objectStates?.length];

    useEffect(() => {
        if (addressConnected && addressConnected !== address) {
            window.location.reload();
        }
        if(!addressConnected && address){
            setAddressConnected(address);
        }
    }, [address, addressConnected]);

    useEffect(() => {
        if (!iboInfos.objectStates.includes(GET_BOND_PARAMS_LOADING)) {
            loadIboData(address, provider);
        }
        if (iboInfos.isPrivilege && !iboInfos.objectStates.includes(GET_PROOF_LOADING)) {
            loadProof(address);
        }
        
    }, [check]);

    const loadIboData = async () => {
        dispatchIboInfos({type : GET_BOND_PARAMS_LOADING })

        iboService.loadIboInit(address, provider)
            .then((iboData) => {
                dispatchIboInfos({
                    data: iboData,
                    type: GET_BOND_PARAMS_LOADED,
                });
            })
            .catch((e) => {
                dispatchIboInfos({
                    type: GET_BOND_PARAMS_ERROR,
                });
            });
    };


    const reloadIboStaticData = async () => {
        dispatchIboInfos({type : GET_RELOADED_DATA_LOADING })

        iboService.loadIboInit(address, provider)
            .then((iboData) => {
                dispatchIboInfos({
                    data: iboData,
                    type: GET_RELOADED_DATA_LOADED,
                });
            })
            .catch((e) => {
                dispatchIboInfos({
                    type: GET_RELOADED_DATA_ERROR,
                });
            });
    };

    const loadProof = async () => {
        dispatchIboInfos({type : GET_PROOF_LOADING })
        iboService.getMerkle(address, provider)
            .then((merkleData) => {
                dispatchIboInfos({
                    data: merkleData,
                    type: GET_PROOF_LOADED,
                });
            })
            .catch((e) => {
                dispatchIboInfos({
                    type: GET_PROOF_ERROR,
                });
            });
    };


    const _reloadData = async (bondName) => {
        return new Promise((resolve, reject) => {
            dispatchIboInfos({ type: UPDATE_ONE_BOND_LOADING, bondName : bondName  });
            iboService
                .getOne(address, bondName, provider)
                .then((bondData) => {
                    dispatchIboInfos({ type: UPDATE_ONE_BOND_LOADED, bondName : bondName, payload : bondData});
                    resolve(bondData);
                })
                .catch((e) => {
                    dispatchIboInfos({ type: UPDATE_ONE_BOND_ERROR  });
                    notifyError(e);
                    reject();
                });
        });
    };



    function handleApprove(tokenName) {
        const erc20Address = iboInfos.bondsData[tokenName].staticData.token.tokenAddress;
        _doApprove(erc20Address, ethers.constants.MaxUint256, signer).then((tx) => {
            if (typeof (tx) === 'string') {
                notifyError(tx);
                return;
            }

            notifyBeginTransaction();
            tx.wait().then((tXResult) => {
                if (tXResult && tXResult.blockNumber) {
                    notifyEndTransaction(true)
                    _reloadData(tokenName);

                    // updateUserInfo({loading: true});
                }
            }).catch(() => {
                notifyEndTransaction(false)
            });
        });
    }

    const _doApprove = async (contractAddress, allowance, signer) => {
        const contract = new Contract(contractAddress, erc20.abi, signer);
    
        return await callBc(async () => {

            return await contract.approve(process.env.REACT_APP_CONTRACT_IBO, allowance);
        });
    }

    function handleDeposit(tokenName) {
        let bondId;
        if(tokenName === "FRAX"){
            bondId = 1;
        }
        else if(tokenName === "CRV"){
            bondId = 2;
        }
        else if(tokenName === 'CVX'){
            bondId = 3;
        }
        const bondInfos = iboInfos.bondsData[tokenName];
        const valueToInvest = parseEther(bondInfos.activeData.inputValueUnderlaying.toFixed(18));
        const cvgToReceive = parseEther(bondInfos.activeData.inputValueCvg.toFixed(18));
        const amountOutMin = parseEther((Number(formatEther(cvgToReceive)) * (1 - bondInfos.activeData.activeSlippage.code)).toFixed()) ;
        _doDeposit(bondInfos.activeData.activeDropdownConfig.code, bondId, valueToInvest, amountOutMin, signer).then((tx) => {
            if (typeof (tx) === 'string') {
                notifyError(tx);
                return;
            }

            notifyBeginTransaction();
            tx.wait().then((tXResult) => {
                if (tXResult && tXResult.blockNumber) {
                    _reloadData(tokenName);
                    notifyEndTransaction(true)
                    // updateUserInfo({loading: true});
                }
            }).catch(() => {
                notifyEndTransaction(false)
            });
        });
    }

    const _doDeposit = async (tokenId, bondId, amountIn, amountOutMin, signer) => {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_IBO, ibo.abi, signer);
    
        return await callBc(async () => {
            const privilegeType = iboInfos?.merkleData?.listingType === 'wl' ? 1 : 0;
            return await contract.deposit(tokenId, bondId, amountIn, amountOutMin, privilegeType, iboInfos?.merkleData?.proof ? iboInfos.merkleData.proof : [constants.HashZero]  );
        });
    }




    const providerValue = {
        iboInfos,
        dispatchIboInfos,
        handleApprove,
        handleDeposit,
        addressConnected,
        reloadIboStaticData
    };

    return (
        <IboContext.Provider value={providerValue}>{children}</IboContext.Provider>
    );
};

export default IboProvider;
export {IboContext};