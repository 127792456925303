
import React from "react";


function Panel({className, children}){
  return (
    <div className={`Panel  ${className} `}>
      {children}
    </div>
  )
}

export default Panel;