import {bigNumberFormat, bigNumberToInt} from "../actions/utils";
import Panel from "./Panel";

function NftItem({item, nftTpe}) {
    function getType(item) {
        const type = bigNumberToInt(item?.vestingType);

        const list = ["-", "Seed-PreSeed", "Presale(WL)"];
        if (list[type]) return list[type];
        return "-";
    }

    const getImage = () =>
    {


        switch (nftTpe) {
            case "Seed":
                return "/images/blue-nft.webp";
            case "Presale":
                return "/images/yellow-nft.webp";
            case "IBO":
                return "/images/red-nft.webp";
            default:
                return "/images/cvg-nft.webp";
        }
    }

    return (
        <Panel className="NftPanel">
            <div className="text-left">Token Id: {item.tokenId.toString()}</div>
            <div className="text-left mt-2">
                Status: <span className="Panel-title"> Locked </span>
            </div>
            <div className="NftPanel-image">
        <img src={getImage()} alt="Convergence" />
            </div>
            <div className="NftPanel-kp ">
                <span> CVG amount: </span>
                <span className="NftPanel-kp-value">
          {`${bigNumberFormat(item.cvgAmount)} CVG`}{" "}
        </span>
            </div>
        </Panel>
    );
}

export default NftItem;
