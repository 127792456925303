import { useContext } from 'react';
import { IboContext } from '../../context/IboContext';
import { formatEther, parseEther } from 'ethers/lib/utils.js';
import { TOGGLE_DROPDOWN } from '../../context/ibo.reducer';

function BondListRowDisplay({bondInfos}) {
    const { dispatchIboInfos } = useContext(IboContext);

    const fillingPercentage = Math.trunc((Number(formatEther(bondInfos.staticData.totalCvgMinted)) / Number(formatEther(bondInfos.staticData.maxCvgToMint))) * 100);

    return (
        <div
            className="cursor-pointer flex flex-column md:flex-row justify-content-between align-items-center "
            onClick={() => {
                dispatchIboInfos({type : TOGGLE_DROPDOWN, bondName : bondInfos.staticData.token.token});
            }}
        >
            <div className="md:w-8rem mr-2 ">
                <div className="flex align-items-center">
                    <img
                        src={`/images/token/${bondInfos.staticData.token.token.toUpperCase()}.svg`}
                        alt="logo"
                        className="staking-asset-logo  w-3rem mr-2"
                        title={bondInfos.staticData.token.token}
                    />
                    <div className="title ml-2 px-2 py-1">{bondInfos.staticData.token.token}</div>
                </div>
            </div>

            <div className="md:w-15rem text-center">
                {Number(formatEther(bondInfos.staticData.totalCvgMinted)).toLocaleString('en-US', { maximumFractionDigits: 0 })} / {Number(formatEther(bondInfos.staticData.maxCvgToMint)).toLocaleString('en-US')} (
                {fillingPercentage}%)
            </div>
            <div className="md:w-4rem info-label text-center roi py-1">{bondInfos.staticData.bondRoi / 10000}%</div>
            <div className="md:w-5rem text-center">${formatEther(bondInfos.staticData.bondPriceUsd)}</div>

            <div className="md:w-2rem text-center">
                <button
                    className="Btn Btn-nostyle"
                    onClick={() => {
                        // dispatchIboInfos({type : TOGGLE_DROPDOWN, bondName : bondInfos.token.token});
                    }}
                >
                    <i className={`pi  text-xl pi-chevron-${bondInfos.activeData.isExpanded ? 'up' : 'down'}`} />
                </button>
            </div>
        </div>
    );
}

export default BondListRowDisplay;
