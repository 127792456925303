import React, {useState} from 'react';


const NotifyContext = React.createContext(null);


const NotifyProvider = ({children}) => {

    const [notify, setNotify] = useState({});


    const handleTransaction = async (tx, callBack) => {
        if (typeof (tx) === 'string') {
            notifyError(tx);
            return;
        }

        notifyBeginTransaction();
        tx.wait().then((tXResult) => {
            if (tXResult && tXResult.blockNumber) {
                notifyEndTransaction(true)
                if (typeof (callBack) === 'function')
                    callBack()
            }
        }).catch(() => {
            notifyEndTransaction(false)
        });
    }

    const notifyBeginTransaction = () => {
        setNotify({type: 'warn', tx: true});
    }
    const notifyEndTransaction = (success) => {
        const message = success ? 'transaction succeed' : 'transaction failed';
        setNotify({message: message, type: success ? 'success' : 'error', tx: true});
    }

    const notifyError = (message) => {
        setNotify({message: message, type: 'error'});
    }
    const notifySuccess = (message) => {
        setNotify({message: message, type: 'success'});
    }

    const providerValue = {
        notify, notifyError, notifySuccess, notifyBeginTransaction, notifyEndTransaction, handleTransaction
    };

    return (
        <NotifyContext.Provider value={providerValue}>{children}</NotifyContext.Provider>
    );

}

export default NotifyContext;
export {NotifyProvider};