import {
    createClient,
    configureChains, chain
} from 'wagmi'

import {publicProvider} from 'wagmi/providers/public'
import {jsonRpcProvider} from 'wagmi/providers/jsonRpc'
import {connectorsForWallets, wallet} from "@rainbow-me/rainbowkit";


const chainKey = process.env.REACT_APP_CHAIN_KEY
// const chainId = process.env.REACT_APP_CHAIN_ID
let client, providers, currentChain;

if (chainKey === 'hardhat') {
    providers = [publicProvider()]
    currentChain = chain[chainKey];
} else if (chainKey === 'CVG') {
    currentChain = {
        id: 31337,
        name: 'CVG',
        network: 'CVG',
        nativeCurrency: {
            decimals: 18,
            name: 'CVG',
            symbol: 'GO',
        },
        rpcUrls: {
            default: process.env.REACT_APP_CHAIN_RPC,
        },
        blockExplorers: {
            default: {name: 'eternal', url: process.env.REACT_APP_ADDRESS_EXPLORER},
        },
        testnet: true,
    }

    providers = [  jsonRpcProvider({
        rpc: (ch) => {
            return {http: process.env.REACT_APP_CHAIN_RPC}
        },
    }),];

} else {

    currentChain = chain[chainKey];
    providers = [
        jsonRpcProvider({
            rpc: (ch) => {
                return {http: process.env.REACT_APP_CHAIN_RPC}
            },
        }),
        publicProvider()
    ]
}
const {chains, provider, webSocketProvider} = configureChains([currentChain], providers);

// noinspection JSCheckFunctionSignatures
const connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: [
            wallet.metaMask({
                chains, options: {
                    shimDisconnect: true,
                    UNSTABLE_shimOnConnectSelectAccount: true,
                }
            }),
            wallet.walletConnect({chains}),
            wallet.coinbase({chains}),
            wallet.brave({chains})
        ],
    },
]);


client = createClient({
    provider,
    autoConnect: true,
    webSocketProvider,
    connectors
})


export {client, chains, provider};