// Inspired  by  (GitHub : https://github.com/sarathsaleem/grained)
import React from "react"

function Grained() {
  return (
    <div className="Grained" />
  )
}
export default Grained;


