
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

function BondListActionChart({bondInfos}) {
    const colorVested = '#6d6dab';
    const colorClaimable = '#21219b';
    const renderTooltip = ({ payload }) => {
        const cvgValue = Math.floor(payload[0]?.payload?.cvgValue);
        const cvgValueModified = Math.floor(payload[0]?.payload?.cvgValueModified);
        if(cvgValue && cvgValueModified) {
            return (
                <div className=" color-black p-2 text-left">
                    <div className="font-bold">After: {Math.floor(cvgValueModified)}</div>
                    <div className="font-bold">Before: {Math.floor(cvgValue)}</div>
    
                </div>
            );
        }
        else if (cvgValue){
            return (
                <div className=" color-black p-2 text-left">
                    <div className="font-bold">{Math.floor(cvgValue)}</div>
                </div>
            );
        }
        

    };
    return (
        <>
            {bondInfos.activeData.graphData.length !== 0 ? (
                <div>
                    <ResponsiveContainer width={'100%'} height={225}>
                        <AreaChart width={300} height={300} data={bondInfos.activeData.graphData} margin={{ top: 15, left: 15, right: 30, bottom: 5 }}>
                            <XAxis
                                dataKey="moment"
                                allowDuplicatedCategory={false}
                            />

                            <YAxis tick={false} />
                            <Tooltip content={renderTooltip} />
                            <Area type="linear" dataKey="cvgValue" stroke={colorVested} fill={colorVested} stackId="1"  />
                           
                            { bondInfos.activeData.graphData[1].cvgValueModified 
                                ? 
                                    <Area type="linear" dataKey="cvgValueModified" stroke={colorClaimable} fill={colorClaimable} stackId="2"  /> 
                                : 
                                ''
                            }
                        
                        </AreaChart>
                    </ResponsiveContainer> 
                </div>

            ) : (
                <div className="flex align-items-center justify-content-center h-10rem">
                    <span className="">No graph to display</span>
                </div>
            )}


        </>
    );
}

export default BondListActionChart;
