import { useContext, useState } from "react";
import { BcContext } from "../context/BcContext";
import Panel from "./Panel";
import { SALE_STATE } from "../actions/SmartContractActions";
import { bigNumberFormat } from "../actions/utils";
import { Dialog } from "primereact/dialog";
import VestingInfoGraph from "./VestingInfoGraph";
import { getVestingByTYpe } from "../actions/SmartContractActions";

const vesting = getVestingByTYpe("WL");

function PresaleStages() {
  const { bcState } = useContext(BcContext);
  const [displayDialog, setDisplayDialog] = useState(false);

  function StagePanel({ type, label, info }) {
    const isActive = bcState?.saleState === SALE_STATE[type];
    const priceDisplay = isActive ? (
      <div className="Price py-2">
        {" "}
        Price: {bigNumberFormat(info?.price)} USD
      </div>
    ) : (
      <></>
    );

    return (
      <div>
        <div className="Panel-title"> {label}</div>
        {priceDisplay}
        <div
          className="cursor-pointer align-items-center"
          onClick={() => setDisplayDialog(true)}
        >
          <a className="color-tonic underline">Vesting info:</a>

          <button
            type="button"
            className="ml-3 text-left Btn Btn-nostyle color-tonic"
          >
            <i className="pi pi-calendar" style={{ fontSize: "1.6rem" }}></i>
          </button>
        </div>
      </div>
    );
  }

  return (
    <Panel className="  align-items-center justify-content-center ">
      Welcome anon. If your address has been whitelisted, you will be able to
      mint an NFT. This NFT will allow you to redeem CVG when the protocol will
      go live.
      <br /> <br />
      <StagePanel type="WL" label="Presale(WL)" info={bcState?.info["wl"]} />
      <Dialog
        header="Vesting informations"
        visible={displayDialog}
        onHide={() => setDisplayDialog(false)}
        modal
        className="w-11 md:w-6"
      >
        <>
          <div>
            <dl className="p-3 ">
              <dt className="color-tonic">Cliff</dt>
              <dd className="ml-0 mb-4">
                You will have{" "}
                <b className="color-bg-tonic">
                  {vesting?.cliff_percent * 100}%
                </b>{" "}
                of your allocation at day{" "}
                <b className="color-bg-tonic">{vesting?.cliff_length}</b>
              </dd>
              <dt className="color-tonic">Vesting</dt>
              <dd className="ml-0 mb-4">
                You will have all your tokens after{" "}
                <b className="color-bg-tonic">
                  {vesting?.vesting_length * vesting?.vesting_cycle}
                </b>{" "}
                days
              </dd>
            </dl>
            <div style={{ height: "250px" }}>
              <VestingInfoGraph vesting={vesting} />
            </div>
          </div>
        </>
      </Dialog>
    </Panel>
  );
}

export default PresaleStages;
