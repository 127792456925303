import React from "react"



function KeyValueDisplay({ label, value, className}){
  return (
    <div className={`KeyValueDisplay  ${className||''} `}>
      <span className="KeyValueDisplay-label" > {label} </span>
      <span className="KeyValueDisplay-value"> {value}</span>
    </div>
  )
}

export default KeyValueDisplay;
