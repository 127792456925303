import React, { useContext, useEffect, useState } from "react";

import { IboContext } from "../../context/IboContext";

import Panel from "../Panel";
import Loader from "../Loader";
import { secondsToHourMinutsSeconds } from "../../actions/utils";
function IboHeader() {
  const { iboInfos, reloadIboStaticData } = useContext(IboContext);
  const iboGlobalData = iboInfos?.iboGlobalData;
  const [counter, setCounter] = useState(undefined);

  useEffect(() => {
    if (iboGlobalData?.timeLeft && counter === undefined) {
      setCounter(iboGlobalData.timeLeft);
    }
    if (counter % 10 === 0) {
      reloadIboStaticData();
    }
    if (counter) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [iboGlobalData?.timeLeft, counter]);

  if (iboGlobalData === undefined) {
    return (
      <div>
        <Panel className="my-5">
          <Loader />
        </Panel>
      </div>
    );
  }

  const currentDisplay = () => {
    return (
      <>
        <span
          className="Progress-current-indicator"
          style={{ left: `${iboGlobalData.percentageBought}%` }}
        >
          <span className="">
            {100 === 0
              ? "-"
              : `${Number(
                  Number(iboGlobalData?.totalSold)?.toFixed("en-US")
                ).toLocaleString("en-US")}`}{" "}
          </span>
        </span>
        <div
          className="Progress-inner"
          style={{ width: `${iboGlobalData.percentageBought}%` }}
        ></div>
      </>
    );
  };

  return (
    <Panel className="my-5">
      <div className="flex flex-column md:flex-row mb-4 align-items-center">
        <div className="w-10 md:w-6 text-center ">
          <div className="text-center text-xl">
            <span className="color-tonic">Progress</span>
            <div className="text-center mt-2 info-label ">
              <span className="roi p-1">
                {iboGlobalData.percentageBought.toLocaleString("en-US")} %
              </span>
            </div>
          </div>
          <div className="Progress">
            <span className="Progress-min-indicator" style={{ left: `${0}%` }}>
              <span>{0}</span>
            </span>
            <span
              className="Progress-max-indicator"
              style={{ left: `${100}%` }}
            >
              <span>
                {Number(iboGlobalData?.totalToSell)?.toLocaleString("en-US")}{" "}
              </span>
            </span>
            {currentDisplay()}
          </div>
        </div>
        <div className="w-12 md:w-6 mt-3 md:mt-0">
          <div className="text-center text-xl color-tonic">Time left</div>
          <div className="mt-2 md:mt-5 text-center text-xl ">
            <span>IBO Ended !</span>
          </div>
        </div>
      </div>
    </Panel>
  );
}

export default IboHeader;
