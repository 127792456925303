import {BigNumber, ethers} from "ethers";
import {Interface} from "ethers/lib/utils";
import Multicall2Json from "./Multicall2.json"




const multicallStandardHydrate = (result) => {
    return (call, value) => {
        let nested = result;
        call.path.forEach((p, idx) => {
            if (idx !== call.path.length - 1) {
                nested[p] = nested[p] || {};
                nested = nested[p];
            } else {
                nested[p] = value[0]
            }
        });
    }
}

const multiCallBc = async (calls, contractAbis, provider, hydrateFunc) => {
    try {
        contractAbis = !Array.isArray(contractAbis) ? [contractAbis] : contractAbis
        const multiCallAddress = process.env['REACT_APP_CONTRACT_MULTICALL']
        const multi = new ethers.Contract(multiCallAddress, Multicall2Json.abi, provider);
        const itfs = contractAbis.map(abi => new Interface(abi.abi));
        const callData = calls.map((call) => {
                const itf = call.abiIndex ? itfs[call.abiIndex] : itfs[0];
                return {
                    target: call.address.toLowerCase(),
                    callData: itf.encodeFunctionData(call.method, call.params),
                }
            }
        );
            
        const returnData = await multi.tryAggregate(false, callData)
        return returnData.forEach((values, i) => {
                const itf = calls[i].abiIndex ? itfs[calls[i].abiIndex] : itfs[0];
                if (values[0] === true) {
                    const parsedResult = itf.decodeFunctionResult(calls[i].method, values[1]);
                    if (typeof hydrateFunc === 'function')
                        hydrateFunc(calls[i], parsedResult)
                }
            }
        );
    } catch (ex) {


        console.error(ex)

        if (ex instanceof TypeError) {
            return ex?.message || 'error';
        }
        return ex?.reason || ex?.message || 'error';
    }
}

const loadConfigFile = async (file) => {
    const response = await fetch(`/config/${file}.json`, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });
    return response.json();
};

const callBc = async (call) => {
    try {
        return await call();
    } catch (ex) {


        console.error = console.error || console.log;
        console.error(ex)

        if (ex instanceof TypeError) {
            return ex?.message || 'error';
        }
        return ex?.reason || ex?.message || 'error';
    }
}

const bigNumberToInt = (number) => {
    if (number instanceof BigNumber) {
        return parseInt(ethers.utils.formatUnits(number.toString(), 0))
    }
    return NaN;
}

const bigNumberFormat = (number, decimals) => {
    if (number instanceof BigNumber) {
        const reduced = ethers.utils.formatUnits(number.toString(), decimals)
        number = numberFormatter(parseInt(reduced));
    }
    return number;
}

function numberFormatter(num) {
    const lookup = [
        {value: 1, symbol: ""},
        // {value: 1e3, symbol: "k"},
        {value: 1e6, symbol: "M"},
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });

    // Create our number formatter.
    const formatter = new Intl.NumberFormat("en-US");
    return item ? formatter.format(num / item.value).replace(rx, "$1") + item.symbol : "-";
}
function secondsToHourMinutsSeconds(secondsDuration) {
    const hours = secondsDuration / 3600;
    const minutesLeft = (secondsDuration % 3600) / 3600 * 60;
    const secondsLeft =(-Math.trunc(minutesLeft) + minutesLeft % 60) *60;

    return Math.trunc(hours) + " hours " + Math.trunc(minutesLeft) + " min " + Math.round(secondsLeft) +" s";
}

function secondsToMinutsSeconds(secondsDuration) {
    const minutes = secondsDuration / 60;
    const minutesLeft = (secondsDuration % 60);
    return Math.trunc(minutes) + " min " + Math.round(minutesLeft) +" s";
}

export {
    callBc,
    numberFormatter,
    bigNumberFormat,
    bigNumberToInt,
    loadConfigFile,
    multiCallBc,
    multicallStandardHydrate,
    secondsToHourMinutsSeconds,
    secondsToMinutsSeconds
}