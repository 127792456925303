import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Panel from "../components/Panel";
import NftPanel from "../components/NftPanel";
import UserInfoLoader from "../components/UserInfoLoader";

function Nft(){
    return (
        <>
            <Menu/>
            <div className="sm:col-12 md:col-offset-1 md:col-10 ">

                <div className="pt-2">
                    <NftPanel/>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export  default  Nft;