

import React, { useContext, useEffect, useState } from 'react';
import {ConnectIbo} from "./ConnectIbo";

import Menu from "../Menu";
import IboProvider, { IboContext } from '../../context/IboContext';
import BondList from './BondList';
import  IboHeader  from "./IboHeader";
import Panel from '../Panel';
import { useAccount } from 'wagmi';
import { BcContext } from '../../context/BcContext';
import DisclaimerDialog from '../DIsclaimerDialog';
import { ConnectButton } from '@rainbow-me/rainbowkit';
function IboContainer() {
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBtn, setDisplayBtn] = useState(false);
    const {address, isConnected} = useAccount()

    
    function setChecked(e) {
        setDisplayBtn(!displayBtn)
    }
    if (!isConnected || address === undefined) {
        return (
            <div>
                <Menu/>
                <Panel>
                    <DisclaimerDialog displayBasic={displayBasic} setDisplayBasic={setDisplayBasic}/>

                    <p className="text-xl"> Please read this
                        <button type="button" className="Btn-nostyle color-tonic underline  bg-transparent"
                                onClick={() => setDisplayBasic(true)}>agreement
                        </button>
                        out herein
                        carefully before clicking the “I AGREE” button on the designated web address.
                    </p>
                    <label htmlFor="disclaimer" className="text-xl inline-block my-4">
                        <input type="checkbox" checked={displayBtn} onChange={setChecked} id="disclaimer"/> I agree
                    </label>
                    <div className="text-center">
                        <div className="ConnectButton-lonely" hidden={!displayBtn}>
                            <ConnectButton/>
                        </div>
                    </div>
                </Panel>
            </div>
        );
    }

    return (
        <IboProvider>
            <Menu/>
            <IboHeader></IboHeader>
            <div className="lg:mx-2 mt-7 lg:mx-8 lg:mt-7">
                <BondList ></BondList>
            </div>
           
        </IboProvider>   
);

}

export default IboContainer;