export const TOKENS = {
    FRAX : {
        address: "0x853d955acef822db058eb8505911ed77f175b99e",
    },
    CRV : {
        address: "0xD533a949740bb3306d119CC777fa900bA034cd52",
    },
    CVX : {
        address: "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
    },
}