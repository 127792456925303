import {useContext, useEffect} from "react";
import {getStaticInfos} from "../actions/SmartContractActions";
import {BcContext} from "../context/BcContext";
import {useAccount, useProvider} from "wagmi";
import NotifyContext from "../context/NotifyContext";

function StateLoader() {


    const { bcState,updateBcState,userInfo} = useContext(BcContext);
    const provider = useProvider();
    const { address,isConnected } = useAccount()
    const {notifyError} = useContext(NotifyContext);

    useEffect(() => {

        if(isConnected && userInfo?.address && address && address!==userInfo?.address){
            window.location.reload();
            return ;
        }
        if (bcState.loading) {
           // notifyBeginTransaction();
            getStaticInfos(provider).then((ob) => {

                if (typeof (ob) === 'string') {
                    notifyError(ob);
                    updateBcState({error: true});
                    return;
                }
                updateBcState(ob);
            });
        }






    }, [address,userInfo?.address,userInfo?.userInfo?.allocationStable])
    return (<></>)

}

export default StateLoader;